.ehon-provided-service-indicator-component
  position: absolute
  bottom: -2vw
  left: 0
  img.ehon-provided-service-indicator-component__image
    width: auto
    height: 5vw
    max-width: 270px
  &.is-top-page
    bottom: -8px
    left: -4px
    img.ehon-provided-service-indicator-component__image
      max-height: 30px
  &.is-ranking-page
    left: auto
    right: -1vw
