.ehon-videos-body
  &--red
    background: $_red_crimson
  &--black
    background: $_black
.ehon-videos-header
  background: $_yellow_sweetcorn
  padding: 0 15px
  display: flex
  justify-content: space-between
  position: relative
  height: 50px
.ehon-videos-header-title
  position: absolute
  bottom: 9px
  margin: 0
  padding: 0
.ehon-videos-header-logo
  width: 195px
.ehon-videos-header-menu
  display: flex
.ehon-videos-navigation-items
  position: absolute
  bottom: 0
  right: 80px
  li
    display: block
    margin-right: 10px
    float: left
  a
    text-decoration: none
.ehon-videos-navigation-link
  display: block
.ehon-videos-navigation-link__image
  min-width: 50px
  max-width: 95px
.ehon-videos-original-service-link
  position: absolute
  right: 10px
  bottom: 10px
.ehon-videos-original-service-link__image
  width: 70px
  margin-top: 10px
.ehon-videos-wrapper
  padding: 4vh 0 0 0
.ehon-videos-content-items
  overflow-x: scroll
  display: flex
  // 右側だけカードが見切れるように
  // https://github.com/OwlCarousel2/OwlCarousel2/issues/172
  & > .owl-stage-outer > .owl-stage
    left: -40px
.ehon-videos-content-item-link
  display: block
  color: $_black
  font-weight: bold
  &:hover, &:focus
    color: $_black
    text-decoration: none
.ehon-videos-content-item
  background: $white
  border-radius: 16px
  margin-bottom: 10px
  box-shadow: inset 0 -1px 5px 0 rgba(0, 0, 0, 0.44)
  border: solid 0.5px $_red_crimson
  max-height: 35vh
  padding: 2vh 2vw 1vh
  span
    font-size: $font-size-xxs
    display: -webkit-box
    -webkit-box-orient: vertical
    -webkit-line-clamp: 1
    overflow: hidden
.ehon-videos-content-item__label-new
  +page-content-label-new($top: 3px, $bottom: initial, $right: -2px, $left: initial, $height: 19px)
.ehon-videos-content-item-thumbnail
  position: relative
  margin: 0 auto
  max-width: 100%
  max-height: 26vh
  border-radius: 10px
  margin-bottom: 2px
img.ehon-videos-content-item-thumbnail__image
  margin: 0 auto
  max-width: 100% !important
  max-height: 26vh
  object-fit: contain
  border-radius: 10px
.ehon-videos-content-item-thumbnail__copyright
  // 横幅が狭いとコピーライト表示がはみ出すので非表示に
  // 目安は iPhone 5c の横幅 568px くらいとした
  @media (max-width: 550px)
    display: none
  position: absolute
  bottom: 5px
  left: 5px
  color: $_white
  font-size: $font-size-xxs
  font-weight: normal
  text-shadow: $_black 0 0 1px
  line-height: 0.9
.ehon-video-player
  text-align: center
  position: relative
.ehon-video-player-link
  position: absolute
  width: 10%
  top: 10px
  right: 10px
  z-index: 100
.ehon-videos-license
  padding: 0 15px
  p
    font-size: $font-size-xxs
    display: inline
    color: $_white
.ehon-videos-license-image
  width: 25px
  height: 25px
  float: left
  margin-right: 5px

//縦画面注意喚起画面用
.landscape-hidden
  display: none
.landscape-visible
  display: block
#landscape-image
  background-size: cover
  background-repeat: no-repeat
  z-index: 1001
  top: 0
  left: 0
  position: fixed
  overflow: hidden
  height: 100%
  width: 100%
  &.iphone
    background-image: url("https://dkids.docomo.ne.jp/cp/iphone_prohibited_virtual_screen.png")
  &.ipad
    background-image: url("https://dkids.docomo.ne.jp/cp/ipad_prohibited_virtual_screen.png")
