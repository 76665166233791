@use '~bootstrap/scss/bootstrap' as b

$_red_orange_shadow: rgba(255, 59, 0, 0.5)
$_red_sweet_shadow: rgba(239, 150, 150, 0.5)

.btn-red-orange
  color: b.$white !important
  background-color: $_red_orange !important
  border-color: transparent !important
  font-weight: bold

.btn-red-orange:focus, .btn-red-orange.focus
  box-shadow: 0 0 0 0.2rem $_red_orange_shadow !important

.btn-red-sweet
  color: b.$white !important
  background-color: $_red_sweet !important
  border-color: transparent !important
  font-weight: bold

.btn-red-sweet:focus, .btn-red-sweet.focus
  box-shadow: 0 0 0 0.2rem $_red_sweet_shadow !important
