.promotion-banner-swiper
  background: white
  .swiper-slide
    width: 375px
    img
      width: 100%
      height: auto
      object-fit: contain
      overflow-x: hidden
  .promotion-banner-item
    display: flex
    justify-content: center
    align-items: center
  .swiper-button-prev, .swiper-button-next
    color: white
    background-color: rgb(81, 51, 24)
    opacity: 0.6
    border-radius: 50%
    width: 50px
    height: 50px
    font-weight: bold
  .swiper-button-prev
    left: -25px
    &:after
      font-size: 1.2rem
      transform: translateX(8px)
  .swiper-button-next
    right: -25px
    &:after
      font-size: 1.2rem
      transform: translateX(-8px)
