.sugotoku-ehon-app-guide
  @extend .container
  margin-bottom: 1rem
.sugotoku-ehon-app-guide__link
  border-radius: 20px
  border: 1px solid $_brown_bramble
  background-color: $_white_ivory
  color: $_brown_bramble
  box-shadow: 0 4px 0 0 $_brown_bramble
  width: 100%
  display: inline-block
  text-align: center
  text-decoration: none
  vertical-align: middle
  font-size: 1.2rem
  line-height: 2
  margin: 10px 0
  &.collapsed
    border: 1px solid $_brown_bramble !important
    background-color: $_white_ivory !important
    color: $_brown_bramble !important
  &:hover
    border: 1px solid $_brown_bramble
    background: $_brown_bramble
    color: $_white_ivory
    text-decoration: none
.sugotoku-ehon-app-guide__login-guide
  @extend .container
  background-color: #fcfaef
  padding: 22px
  h5
    font-size: 20px
    font-weight: bold
    text-align: center
.sugotoku-ehon-app-download-link
  display: block
  img
    display: block
    width: 150px
    margin: 0 auto
