.in-app-purchase-alert-button
  width: 300px
  border: none
  background: #ffd666
  border-radius: 25px
  display: block
  line-height: 50px
  color: black
  font-size: 14px
  font-weight: bold
  text-align: center
  &:hover
    color: black
    text-decoration: none
  &.is-outline
    background: white
    border: #ffd666 3px solid
