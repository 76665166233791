.ehon-acti-books-help
  .card
    .collapse
      background-color: $_white
  .help-item
    display: block
    text-align: left
    text-decoration: none
    color: $_brown_bramble
    &:focus
      box-shadow: none
