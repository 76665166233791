.app-search-form-container
  position: sticky
  top: 0
  background-color: white
  z-index: 1

.app-search-result-count
  color: $gray-700
  font-size: .8rem
  text-align: center
