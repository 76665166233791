.sessions__body
  border-top: #dd0000 0px solid
  &.is-border-shown
    border-top-width: 6px
.sessions__button
  width: 300px
  border: none
  background: #ffd666
  border-radius: 25px
  display: block
  margin: 20px auto
  line-height: 50px
  color: black
  font-size: 14px
  font-weight: bold
  &:hover
    color: black
    text-decoration: none

.sessions__button-sugotoku
  @extend .sessions__button
  background-color: #fff
  border: 3px solid #d56682
  color: #cc0033
  &:hover
    color: #dd0044

.sessions__button-apple
  @extend .sessions__button
  background-color: #fff
  border: 3px solid #636363
  color: black
  &:hover
    color: gray
