@import '../../shared/styles/colors'
@import '../../shared/styles/utils'
@import '../../shared/styles/variables'
@import '../../shared/styles/button'
@import '../../shared/styles/modal'
@import '../../shared/styles/promotion_message'
@import 'initializers/base'
@import 'initializers/mixins'
@import 'overrides/overrides'
@import '~bootstrap/scss/bootstrap'
@import 'shared/shared'
@import 'shared/sugotoku_ehon_app_guide'
@import 'views/campaigns'
@import 'views/premium_home'
@import 'views/acti_book'
@import 'views/movie'
@import 'views/news_column'
@import 'views/special_collection'
@import 'views/gadget'
@import 'views/lp'
@import 'views/banner'
@import 'views/ehon_video'
@import 'views/ehon_acti_book'
@import 'views/ehon_acti_books/static_pages/shared'
@import 'views/ehon_acti_books/static_pages/premium_service_guide'
@import 'views/ehon_acti_books/static_pages/ehon_app_guide'
@import 'views/ehon_acti_books/static_pages/help'
@import 'views/ehon_acti_books/static_pages/signup_guide'
@import 'views/sugotoku_sessions'
@import 'views/ehon_app_footer'
@import 'views/ehon_app_header'
@import 'views/app_home'
@import 'views/profiles'
@import 'views/mypage'
@import 'views/content_modal'
@import 'views/dpoint_mission'
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@600&display=swap')
@import 'views/sessions'
@import 'views/search'
@import 'views/in_app_purchase_alert'
@import 'views/announcement'
@import 'views/app_guide'
@import 'views/in_app_review'

@import 'views/components/contents_carousel'
@import 'views/components/ehon_provided_service_indicator'
@import 'views/components/ehon_already_all_page_trial_icon'

dl, dt, dd, ol, ul, li
  margin: 0
  padding: 0
  list-style: none
