.news-columns-category-content-news-items
  margin: 0 15px
.news-columns-subcategory-items
  +page-subcategory-items
  margin-bottom: 10px
.news-columns-category-content-news-item
  overflow: hidden
  margin-bottom: 20px
.news-columns-category-content-news-item-link
  &:hover, &:focus
    text-decoration: none
.news-columns-category-content-news-item__image
  position: relative
  background: white
  display: block
  float: left
  clear: left
  width: 72px
  height: 72px
  margin-top: 4px
  img
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    max-height: 60px
    display: block
    margin: 0 auto
    width: 100%
.news-columns-category-content-news-item__content
  display: block
  margin-top: 0
  margin-left: 90px
  p
    margin: 5px 0 0
    color: $_brown_bramble
.news-columns-category-content-news-item__label-date
  color: $_brown_bramble
.news-columns-category-label
  +page-content-text-label
  &--1
    background: $_brown_bramble
  &--2
    background: $_red_sweet
  &--3
    background: $_brown_pale
  &--4
    background: $_blue_polo
  &--5
    background: $_green_york
  &--6
    background: $_yellow_tacha

.news-column-header
  padding: 20px 15px 15px
  background-image: url('../images/texture-wood.png')
  background-repeat: repeat-y
  background-size: 100% auto
.news-column-header__label
  margin-bottom: 10px
.news-column-header__label--date
  +page-content-title-text-style
.news-column-header__title
  +page-content-title-text-style
.news-column-back-link
  +page-brown-link
.news-column-content
  padding: 30px 15px 15px 15px
  background: $_white
  font-size: $font-size-sm
  line-height: 1.2rem
  h2
    +page-content-title
  img
    height: auto
    display: block
    margin: 12px 0 12px 0
    width: 100%
  // 移行された HTML ページのクラスを適用している
  h3, .itemIntroduction_komidashi
    padding-left: 10px
    margin: 15px 0
    line-height: 1.5rem
    font-weight: bold
    font-size: 15px
    border-left: 5px solid $_red_orange
    color: $_brown_bramble
  .book-api
    background-image: url('../images/texture-wood.png')
    background-repeat: repeat-y
    background-size: 100% auto
    padding: 20px
    margin-top: 5px
    border-radius: 5px
    margin-bottom: 40px
    h3
      border: none
    .title
      background-image: url('../images/book-icon-s.png')
      background-repeat: no-repeat
      padding-left: 40px
      min-height: 30px
      h4
        font-size: $font-size-lg
        margin-bottom: 20px
      h3
        margin: 0px
        font-size: 17px
        font-weight: bold
        color: #905900
        margin-bottom: 15px
        padding-top: 5px
        padding-bottom: 14px
        border-bottom: 3px solid #efd6ad
        min-height: 33px
        line-height: 110%
  .clientIntroduction
    padding: 15px
    margin: 10px -15px
    background-image: url('../images/texture-wood.png')
    background-repeat: repeat-y
    background-size: 100% auto
  .thumbnail
    img
      width: 60%
      margin: 15px auto
  .cms-ehon-content
    line-height: 1.2rem
  .book-product-api
    > .title
      font-size: 15px
      padding: 15px 20px
      text-shadow: none
      background-color: #58b3b3
      color: #fff
      margin-bottom: 0
      border-top-left-radius: 5px
      border-top-right-radius: 5px
    .body
      border: 1px solid #58b3b3
      margin-bottom: 40px
      > ul li
        margin: 0px
        width: 100%
        display: inline-block
        padding: 0px 20px
        .item
          border-top: 1px solid #f4f4f4
          display: inline-flex
          padding: 7px 0px
          width: 100%
  .item span img
    display: block
    position: relative
    width: 80px
    height: 80px
    margin-right: 15px
    border-radius: 5px

  .book-product-api .body > ul li .item
    color: inherit
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    font-size: 15px
    line-height: 20px
    -webkit-line-clamp: 4
    -webkit-box-orient: vertical
    width: 195px

  margin-b-40
    margin-bottom: 40px
  .surrounding-padding
    padding: 15px
    border-radius: 8px
  .star-list, .orange-list
    padding: 0 40px
    margin-bottom: 20px
    border-radius: 8px
    line-height: 25px
    li
      list-style-image: url('https://style.ehonnavi.net/wp-content/themes/ehon-navi-style/images/star-icon.png')
  h4.htag
    margin: 40px 0
    font-size: 16px
.news-column-banner-wrapper
  padding: 0 15px
