.contents-carousel
  display: flex
  flex-direction: row
  column-gap: .5rem
  padding: 0 .5rem
  overflow-x: auto

.contents-carousel-item
  width: 200px
  height: 200px
  flex-shrink: 0
  position: relative
  outline: none
  margin-bottom: 1rem
  display: flex
  flex-direction: column
  a
    position: relative
  img
    width: 200px
    height: 200px
    object-fit: contain
    object-position: bottom
  +media-breakpoint-down(sm)
    width: 150px
    height: 150px
    img
      width: 150px
      height: 150px

  &.contents-carousel-item--ehon

  &.contents-carousel-item--movie
    align-self: end
    height: 130px
    img
      height: unset

  &.contents-carousel-item--book
    width: 150px
    img
      width: 150px
    +media-breakpoint-down(sm)
      width: 115px
      img
        width: 115px

  &.contents-carousel-item--small-book-with-title
    align-self: end
    width: 90px
    height: unset
    margin: 0 .5rem 1rem
    img
      width: 90px
      height: unset
    .contents-carousel-item__within-deadline
      bottom: 12px

  &.contents-carousel-item--audio-book
    margin-top: .75rem
    height: unset
    +media-breakpoint-down(sm)
      height: unset

.contents-carousel-item__movie-thumbnail
  position: relative
  width: 100%
  img
    border-radius: 6px
    width: 100%
    height: auto
  .play-icon
    position: absolute
    top: 50%
    left: 50%
    transform: translateX(-50%) translateY(-50%)
    color: white
    font-size: 3rem
    opacity: .8
.contents-carousel-item__movie-title
  margin-top: .25rem
  color: $_brown_bramble
  font-size: .75rem
  white-space: nowrap
  text-align: center
  text-overflow: ellipsis
  overflow: hidden
.contents-carousel-item__movie-new
  &::before
    content: ''
    position: absolute
    left: -25px
    top: -20px
    display: block
    transform: rotate(-45deg)
    border-right: 20px solid transparent
    border-top: 20px solid transparent
    border-left: 20px solid transparent
    border-bottom: 20px solid #e62e8b
    z-index: 1
  &::after
    content: 'NEW'
    position: absolute
    left: -5px
    top: 5px
    display: block
    transform: rotate(-45deg)
    color: white
    z-index: 1
    font-size: 9px
    font-weight: bold

.contents-carousel-item__book-title
  font-size: .75rem
  white-space: nowrap
  text-align: center
  text-overflow: ellipsis
  overflow-x: hidden
  margin-top: .25rem

.contents-carousel-item__audio-book-title
  color: $_brown_bramble
  margin-top: .5rem
  font-size: .85rem
  text-align: center

.contents-carousel-load-more-spinner
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center

.contents-carousel-item__within-deadline
  position: absolute
  min-width: 3rem
  right: -.25rem
  bottom: 8px
  transform: rotate(-45deg)
  text-align: center
  color: white
  font-weight: bold
  font-size: 0.625rem
  &:before
    content: ""
    display: block
    position: absolute
    left: -1rem
    bottom: -1rem
    right: -1rem
    z-index: -1
    border-right: 1rem solid transparent
    border-top: 1rem solid #e62e8b
    border-left: 1rem solid transparent
    border-bottom: 1rem solid transparent

  &.is-small
    +media-breakpoint-down(sm)
      font-size: 0.5rem
      min-width: 2rem
      &:before
        bottom: -.75rem
        border-right-width: .75rem
        border-top-width: .75rem
        border-left-width: .75rem
        border-bottom-width: .75rem
