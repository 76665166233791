$_docomo-bg-red: #B62C35
$_docomo-fg-red: #911D0D

.dpoint-mission-banner-component
  position: relative
  padding-top: 1rem
  padding-bottom: 1rem
  background-color: #FFFFFF

.dpoint-mission-page
  font-size: 1rem
  max-width: 768px
  margin: 0 auto

.dpoint-mission-help-card-link
  color: #000000
  &:hover
    color: #000000
    text-decoration: none
.dpoint-mission-help-card
  background-color: #FBF3CB
  font-size: clamp(0.8rem, -0.019rem + 5.04vw, 2.4rem)
.dpoint-mission-help-card__contents-container
  display: flex
  gap: 1rem
.dpoint-mission-help-card__contents-left
  width: 20%
  img
    width: 100%
.dpoint-mission-help-card__contents-right
  width: 0
  flex-grow: 1
  text-align: center
.dpoint-mission-help-card__contents-title
  color: #DE4B3D
  font-weight: 900
  text-shadow: 1px 1px 0 #FFFFFF, -1px 1px 0 #FFFFFF, 1px -1px 0 #FFFFFF, -1px -1px 0 #FFFFFF
.dpoint-mission-help-card__contents-em-text
  color: #F53236
  font-weight: 900
.dpoint-mission-help-card__contents-em-large-text
  color: #F53236
  font-weight: 900
  font-size: clamp(1.2rem, 0.176rem + 6.3vw, 3.2rem)
.dpoint-mission-help-card__contents-text
  font-weight: 900
.dpoint-mission-help-card__banner-container
  margin-top: .5rem
  display: flex
  gap: 1rem
  align-items: center
.dpoint-mission-help-card__banner-left
  width: 0
  flex-grow: 1
.dpoint-mission-help-card__banner-right
  width: 0
  flex-grow: 1
  text-align: center

.dpoint-mission-help-title
  color: $_docomo-fg-red
  text-align: center
  font-size: 1.2rem
  font-weight: bold

.dpoint-mission-help-list
  ul, li
    list-style: disc

.dpoint-mission-help-link
  word-break: break-all
  color: unset
  text-decoration: underline
  &:hover
    color: unset

.dpoint-mission-modal
  .modal-dialog
    max-width: 280px
    margin: auto
    .modal-body
      padding: 0
    @media (max-height: 680px)
      // 縦が狭い場合もとのデザインでは閉じるボタンが画面外になってしまう。
      // そのためボタンを画面内に表示できるように必要に応じて margin-top を調整している。
      margin-top: 2.4rem
// TODO: .modal__close-button に置き換える
.dpoint-mission-modal__close-button
  position: absolute
  z-index: 10
  top: 0
  right: 0
  transform: translateY(-100%)
  color: #FFFFFF
  font-size: 1.2rem
.dpoint-mission-modal__image-wrapper
  position: relative
.dpoint-mission-modal__band
  padding: .5rem
  background-color: rgba(145, 29, 13, .8)
.dpoint-mission-modal__band--center
  position: absolute
  z-index: 2
  top: 45%
  left: 0
  right: 0
.dpoint-mission-modal__band-text
  color: #FFFFFF
  font-size: 1.3rem
  font-weight: bold
  word-break: keep-all
  overflow-wrap: break-word
.dpoint-mission-modal__band-text--sm
  font-size: 1.1rem
.dpoint-mission-modal__dpoint-attention-wrapper
  text-align: center
  line-height: 1.2
.dpoint-mission-modal__dpoint-attention
  color: #FFFFFF
  font-size: .75rem
.dpoint-mission-modal__banzai
  position: relative
  z-index: 1
  padding: 0 10px
  background-color: #FFFFFF
  width: 100%
.dpoint-mission-modal__coin
  background-color: #FFFFFF
  width: 100%
  margin-top: -80px
.dpoint-mission-modal__special-container
  height: 100px
  position: absolute
  left: 0
  right: 0
  background-color: #FFFFFF
  display: flex
  justify-content: center
  align-items: center
  gap: 2rem
.dpoint-mission-modal__dpoint-icon
  img
    width: 5rem
    height: 5rem
.dpoint-mission-modal__special-point
  text-align: center
  color: $_docomo-fg-red
  font-size: 3rem
  line-height: 1
  font-weight: bold
.dpoint-mission-modal__special-point-unit
  color: $_docomo-fg-red
  font-size: 1rem
  line-height: 1
.dpoint-mission-modal__link
  background-color: $_docomo-bg-red
  a
    display: block
    text-align: center
    padding: .6rem
    font-size: 1.2rem
    font-weight: bold
    text-decoration: underline
    color: #FFFFFF
    &:hover
      color: #FFFFFF
.dpoint-mission-modal__link--blue
  background-color: #204783

.dpoint-mission-banner-image
  max-width: 768px
  margin: 0 auto
  img
    max-width: 100%

.dpoint-mission-banner-close-button
  position: absolute
  top: -32px
  right: 0
  font-size: 1.5rem
  width: 32px
  height: 32px
  padding: 0
.dpoint-mission-banner
  display: flex
  flex-direction: column
  align-items: center
.dpoint-mission-banner__title
  color: $_docomo-fg-red
.dpoint-mission-banner__progress-bar-container
  width: 90%
  max-width:360px
  display: flex
  align-items: center
  gap: .25rem
.dpoint-mission-banner__progress-bar
  flex-grow: 1
  height: .8rem
  border-radius: 20px
  .progress-bar
    background-color: #EA9999
.dpoint-mission-banner__progress-bar-status-text
  width: 5rem
  text-align: center
  color: #999999
  font-weight: bold
.dpoint-mission-banner__progress-bar-status-text--completed
  color: $_docomo-fg-red
.dpoint-mission-banner__buttons-container
  width: 90%
  max-width: 360px
  display: flex
  gap: .5rem
.dpoint-mission-banner__receive-button
  width: 0
  flex-grow: 1
  color: #FFFFFF
  background-color: $_docomo-bg-red
  font-weight: bold
  border-radius: 20px
.dpoint-mission-banner__history-button
  width: 0
  flex-grow: 1
  color: #FFFFFF
  background-color: #E06666
  font-weight: bold
  border-radius: 20px

.dpoint-mission-header
  display: flex
  justify-content: center
  align-items: baseline
  gap: .25rem
.dpoint-mission-header__logo
  img
    width: 2rem
    height: 2rem
    transform: translateY(-4px)
.dpoint-mission-header__label
  color: $_docomo-fg-red
  font-size: 1.3rem
  font-weight: bold
.dpoint-mission-header__label--sm
  font-size: 1.1rem
.dpoint-mission-header__info
  font-size: 1.1rem
  a
    color: #B1B1B1
    &:hover
      color: #B1B1B1

.dpoint-mission-card__body
  padding: .5rem
  display: flex
  flex-direction: column
  gap: .25rem
.dpoint-mission-card__title
  align-self: center
  color: $_docomo-fg-red
.dpoint-mission-card__status
  color: #999999
  font-weight: bold
.dpoint-mission-card__status--completed
  color: $_docomo-fg-red
.dpoint-mission-card__deadline
  display: flex
  align-items: center
  gap: .25rem
.dpoint-mission-card__deadline-icon
  color: #B1B1B1
  font-size: 1.5rem
.dpoint-mission-card__deadline-text
  color: #999999
.dpoint-mission-card__button
  width: 80%
  max-width: 360px
  align-self: center
  background-color: $_docomo-bg-red
  color: #FFFFFF
  font-weight: bold
  border-radius: 20px
  &:hover
    color: #FFFFFF
  &:disabled
    background-color: #D9D9D9
    opacity: 1

.dpoint-page-link
  width: 80%
  max-width: 360px
  background-color: $_docomo-bg-red
  color: #FFFFFF
  border-radius: 20px
  display: flex
  justify-content: center
  align-items: baseline
  gap: .25rem
  &:hover
    color: #FFFFFF
.dpoint-page-link__logo
  img
    width: 1.2rem
    height: 1.2rem
    transform: translateY(-1px)

.dpoint-mission-bottom-home-link
  font-weight: bold
  color: $_docomo-fg-red
  &:hover
    color: $_docomo-fg-red

.dpoint-mission-title
  margin-left: -1rem
  margin-right: -1rem
  padding: .5rem 0
  font-size: 1.25rem
  font-weight: bold

.dpoint-mission-title--premium
  color: $_docomo-fg-red
  background-color: lighten(#e2babb, 7.5%)

.dpoint-mission-title--ohanashi
  color: #1c4587
  background-color: lighten(#b9cdf0, 7.5%)

.dpoint-mission-point-notice
  color: $_docomo-fg-red

.dpoint-mission-ehon-image
  height: 200px
  width: auto

.dpoint-mission-weekday-progress
  padding: 2px 4px
  border-radius: 4px
  margin-left: .5rem
  font-size: 1rem
  color: #999
  background-color: #efefef
  &:first-child
    margin-left: 0

.dpoint-mission-weekday-progress--completed
  color: $_docomo-fg-red
  background-color: lighten(#e2babb, 7.5%)
  font-weight: bold

.dpoint-mission-mutual-link-banner
  width: 100%
  height: auto
  max-width: 350px

.dpoint-mission-monthly-progress
  color: $_docomo-fg-red
  font-size: 16px

.dpoint-mission-monthly-progress__days
  font-size: 20px
  font-weight: 900
