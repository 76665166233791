.ehon-acti-books-page-header
  display: flex
  justify-content: space-between
  position: relative
  padding: 10px 0px 10px 0px
  font-size: $font-size-sm
  background-color: $_white
  h1
    color: $_brown_bramble
    font-size: $font-size-base
    font-weight: normal
    line-height: 1.3
    margin: 7px 0 8px 40px
    white-space: nowrap
  a, a:hover, a:focus
    text-decoration: none
.ehon-acti-books-page-header-logo-area
  img
    height: 30px
  &.is-sugotoku
    img
      height: 50px
      margin-left: -.75rem
.ehon-acti-books-page-header-logo-area__label
  margin-left: .75rem
  color: $_red_orange
  line-height: 1.25
  font-size: .6rem
.ehon-acti-books-page-header-logo-area__label-main
  font-size: 1.1rem
  font-weight: bold
.ehon-acti-books-page-header-logo-area-sub-text
  font-size: .65rem
  margin-left: -.25rem
  color: #888
  letter-spacing: .75px
.ehon-acti-books-page-header-member-type
  display: flex
  margin: 0 0 0 auto
  vertical-align: middle
  &.is-premium
    padding-right: .5rem
  .signin-button
    position: relative
    margin: 4px 1rem auto
    img
      height: 22px
    .signin-button-label
      color: #f65026
      position: absolute
      left: 50%
      transform: translateX(-50%)
      white-space: nowrap
      font-size: 8px
  .announcement-icon
    position: relative
    font-size: 1.5rem
    color: $_brown_bramble
    display: flex
    justify-content: center
    align-items: center
    .announcement-icon__unread
      position: absolute
      right: 0
      bottom: 7px
      width: 7.5px
      height: 7.5px
      background-color: $_red_orange
      border-radius: 10px
  .member-type-text
    margin: auto 10px
    border-radius: 100px
    padding: 2px 16px
    font-size: .8rem
    background-color: $gray-200
    color: $gray-700
  .member-type-text--premium
    color: $_red_orange
    background-color: lighten($_red_orange, 42.5%)
    font-weight: bold
  .sugotoku-app-promotion
    color: $_red_orange
    border-top-left-radius: 100px
    border-bottom-left-radius: 100px
    background-color: lighten($_red_orange, 42.5%)
    padding: 2px 8px 2px 16px
    font-size: 0.8rem
    font-weight: bold
    margin: auto 0

.ehon-acti-books-header-nav
  display: flex
  background-color: #f5f4f0
  padding: 8px
  overflow-x: auto
  margin-bottom: 0
  +media-breakpoint-up(md)
    justify-content: center

.ehon-acti-books-header-nav-item
  display: block
  min-width: 80px
  height: 70px
  padding: 0
  margin-left: 8px
  white-space: nowrap
  text-align: center
  font-size: .6rem
  border-radius: 6px
  box-shadow: 2px 2px 0px 0px #dedede
  list-style: none
  background-color: $_white
  +media-breakpoint-up(md)
    margin-left: 16px
  &:first-child
    margin-left: 0
  a
    display: flex
    flex-direction: column
    color: $_brown_bramble
    height: 100%
    .ehon-acti-books-header-nav-item-icon
      flex: 1
      display: flex
      align-items: center
      justify-content: center
      img
        width: 28px
        height: auto

.ehon-acti-books-header-nav-item--ohanashi
  letter-spacing: -1.25px

.ehon-acti-books-header-sugotoku-notification
  padding: 1rem
  font-size: .88rem
  color: $_brown_bramble
  a
    color: $_brown_bramble
    &:hover
      color: $_brown_bramble
  text-align: center
  .modal
    text-align: initial
