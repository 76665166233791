=ehon-acti-book-overlay($color: $_white)
  z-index: 88888 // NOTE: 他の要素を上に重ねられるように
  display: block
  background: $color
  width: 100%
  height: 100%
  position: fixed
  top: 0
  bottom: 0
  right: 0
  left: 0
  opacity: 0.9

// home
.ehon-app-home-category-content-items
  width: 100%
  padding-top: 48px
  min-height: 156.75px
  +media-breakpoint-up(md)
    min-height: 184px
  & a:hover, & a:focus
    text-decoration: none
  .owl-stage-outer
    height: 29vw !important
    max-height: 136px
.ehon-app-home-category-content-item
  position: relative
  align-items: flex-end
  height: 27vw
  max-height: 120px
.ehon-app-home-category-header-link
  @include page-header-link
  text-decoration: none
  font-size: 12px
  margin-top: 7.5px
  +media-breakpoint-up(md)
    font-size: $font-size-base

// container
.ehon-acti-book-container
  color: $_brown_bramble
  margin: 20px 15px
  text-align: left
  +media-breakpoint-up(md)
    width: 60vw
    margin: 0 auto
  h2
    font-size: $font-size-base
    font-weight: bold
  dl
    font-size: $font-size-base
  dt
    display: inline-block
  dd
    display: inline-block
  p
    margin-top: 1rem
    font-size: $font-size-base

.ehon-acti-book-info-text
  font-size: $font-size-sm
  margin-bottom: 15px
=viewer-base
  z-index: 1000
  position: fixed
  border: none
  top: 0
  right: 0
  bottom: 0
  left: 0
  width: 100%
  height: 100%
.ehon-acti-books-contents
  padding: 0
  background-repeat: repeat-y
  background-size: 100% auto
  // TODO: 絵本のモーダルだけに当たるセレクタにしたい
  .modal:not(.dpoint-mission-modal):not(.promotion-message)
    .modal-dialog
      margin: 0
      max-width: 100%
      .modal-body
        padding: 0

.modal-body-viewer
  display: none
.modal-body-viewer-content
  z-index: 5000
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  background: #efefef
  iframe
    border: none
    width: 100%
    height: 100%
.modal-body-viewer-close
  background: rgba(0,0,0,0.4)
  color: white
  padding: 3px 10px
  position: fixed
  left: 0
  top: 40px
  z-index: 10000
  font-weight: bold
  font-size: 1.2rem
  text-decoration: none
  font-family: tahoma,arial,verdana,sans-serif
  cursor: pointer
  &:hover, &:focus
    color: white
    text-decoration: none

.ehon-acti-books-wrapper
  padding-bottom: var(--app-footer-height)

// ハンバーガーメニュー
.ehon-app-menu-drawer
  position: relative
.ehon-app-menu-drawer__checkbox
  display: none
.ehon-app-menu-drawer__icon
  cursor: pointer
  display: block
  font-size: 1.3rem
  .ehon-app-menu-drawer__icon-open
    display: initial
  .ehon-app-menu-drawer__icon-close
    display: none
.ehon-app-menu-drawer__icon-parts
  background-color: $_brown_bramble
  display: block
  margin: auto
  &:before, &:after
    background-color: $_brown_bramble
    display: block
    width: 26px
    height: 2px
    position: absolute
    top: 0
    right: 0
    left: 0
    bottom: 0
    margin: auto
  &:before, &:after
    content: " "
  &:before
    top: 16px
  &:after
    top: -16px
.ehon-app-menu-drawer__overlay
  background: $_brown_bramble
  opacity: 0
  pointer-events: none
  width: 100%
  height: 100%
  position: fixed
  top: 0
  right: 0
.ehon-app-menu-drawer__menu
  background: $_white
  color: $_black
  padding: 20px
  max-width: 100%
  width: 320px
  height: 100vh
  overflow-y: auto
  -webkit-overflow-scrolling: touch
  position: fixed
  top: 0
  right: -320px
h2.ehon-app-menu-drawer__menu-title
  font-size: $font-size-lg
  margin-bottom: 20px
  font-weight: bold
ul.ehon-app-menu-drawer-item-wrapper
  li
    display: block
    border-top: 1px solid $_brown_bramble
    padding: 10px 0
    a
      color: $_brown_bramble
      &:hover, &:active
        color: $_brown_bramble
.ehon-app-menu-drawer__icon
  z-index: 4
.ehon-app-menu-drawer__menu
  z-index: 3
.ehon-app-menu-drawer__overlay
  z-index: 2
.ehon-app-menu-drawer__checkbox:checked ~
  .ehon-app-menu-drawer__icon
    position: fixed
    right: 15px
    top: 15px
    z-index: 100
    .ehon-app-menu-drawer__icon-open
      display: none
    .ehon-app-menu-drawer__icon-close
      display: initial
  .ehon-app-menu-drawer__overlay
    opacity: 0.3
    pointer-events: auto
  .ehon-app-menu-drawer__menu
    right: 0
.ehon-app-menu-drawer__icon-parts
  -webkit-transition: all 0.7s
  transition: all 0.7s
  &:after, &:before
    -webkit-transition: all 0.7s
    transition: all 0.7s
.ehon-app-menu-drawer__overlay, .ehon-app-menu-drawer__menu
  -webkit-transition: all 0.7s
  transition: all 0.7s

.ehon-app-menu-wrapper
  display: flex
a.ehon-app-book-search
  display: block
  margin-right: 10px
  cursor: pointer
  font-size: 1.3rem
  color: $_brown_bramble
  &:hover, &:focus
    text-decoration: none

.ehon-acti-book-search-form
  position: relative
.ehon-acti-book-search-bar-text
  width: 100%
  border: 1px solid $_gray_gainsboro
  border-radius: 6px
  padding: 5px
.ehon-acti-book-search-bar-button
  position: absolute
  border: none
  background: $_brown_bramble
  color: $_white
  border-radius: 0px 5px 5px 0
  padding: 7px
  right: 0
  top: 0
  height: 100%

.ehon-acti-book-link-wrapper
  margin-bottom: 20px
  overflow: hidden
  width: 100%
  +media-breakpoint-up(md)
    width: 60vw
    margin: 0 auto

.ehon-acti-books-help
  overflow-y: scroll

.ehon-acti-books-items
  padding-left: 1vw
  text-align: center
  &.space-top
    padding-top: 14vw
  ul
    li
      display: inline-block
      vertical-align: top
      margin-right: 2.5vw
      &:nth-child(3n)
        margin-right: 0
.ehon-acti-books-item
  background: url('../images/shadow.png') center 6vw no-repeat
  background-size: 28vw
  height: 26.7vw
  width: 26.7vw
  text-align: center
  justify-content: center
  align-items: flex-end
  position: relative
.ehon-acti-books-ranking-item__descriprion
  width: 100%
.ehon-acti-books-item-thumbnail-wrapper
  display: inline-block
  position: relative
  width: 100%
  height: 100%
.ehon-acti-books-item-thumbnail
  object-fit: contain
  object-position: bottom
  width: 100%
  height: 100%
.ehon-acti-books-item-thumbnail-title
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  color: $_brown_bramble
  text-decoration: none
.ehon-acti-books-item-type-icon
  position: absolute
  right: 2px
  bottom: -2vw
  background-color: rgba(255, 255, 255, .85)
  padding: 2px 4px
  border-radius: 4px
  width: 30px
  height: auto
  +media-breakpoint-up(md)
    width: 45px
.ehon-acti-books-item-title
  font-size: .75rem
  margin-top: 4px
  white-space: nowrap
  overflow-x: hidden
  text-overflow: ellipsis
.ehon-acti-books-item.is-movie
  .ehon-acti-books-item-thumbnail
    height: calc(100% - 1rem)
  .ehon-acti-books-item-type-icon
    bottom: calc(1rem - 2px)
.ehon-acti-books-item-link
  outline: none
.ehon-acti-books-item__within-deadline
  position: absolute
  min-width: 2rem
  right: -.25rem
  top: 8px
  transform: rotate(45deg)
  text-align: center
  color: white
  font-weight: bold
  font-size: 0.5rem
  &:before
    content: ""
    display: block
    position: absolute
    left: -1rem
    top: -.75rem
    right: -1rem
    z-index: -1
    border-right: .75rem solid transparent
    border-top: .75rem solid transparent
    border-left: .75rem solid transparent
    border-bottom: .75rem solid #e62e8b
  +media-breakpoint-up(md)
    min-width: 3rem
    font-size: 0.625rem
    &:before
      top: -1rem
      border-right-width: 1rem
      border-top-width: 1rem
      border-left-width: 1rem
      border-bottom-width: 1rem

.ehon-acti-book-home-category-content-item__image
  img
    height: 100px
.ehon-acti-book-category-header
  position: relative
  display: flex
  justify-content: space-between
  padding: 0 15px

.ehon-acti-book-category-header__label
  @extend .page-category-label
  left: -3px
  padding-left: 9px
  +media-breakpoint-up(md)
    font-size: $font-size-base

.ehon-acti-book-category-header__link
  position: absolute
  right: 0

h1.ehon-acti-book-category-header-title
  font-size: $font-size-base
  font-weight: bold
  margin: 0
  line-height: $premium-home-icon-height

.ehon-acti-book-btn
  +page-brown-link
  width: 80%
  margin: 0 auto

.ehon-acti-book-ranking-label
  display: flex
  justify-content: center
  align-items: flex-end
  width: 9.5vw
  height: auto
  position: absolute
  bottom: -2vw
  img
    object-fit: contain
  &.is-large
    left: -2vw
    img
      max-width: 80px
  &.is-small
    left: -1vw
    img
      max-width: 48px

h2.ehon-acti-book-page-title
  font-size: $font-size-lg
  font-weight: bold
  margin: 7px 5px 0 5px

.ehon-acti-book-shopping-link
  display: inline-block
  width: 49%
  margin-bottom: 10px
  font-size: 12px
  float: right
  border-radius: 20px
  border: solid 1px $_brown_bramble
  background-color: $_brown_bramble
  color: $_white

.ehon-acti-book-like-link
  display: inline-block
  width: 49%
  margin-bottom: 10px
  font-size: 12px
  float: left
  border-radius: 20px
  border: solid 1px $_brown_bramble
  background-color: $_white
  color: $_brown_bramble
  &.is-active
    background-color: $_brown_bramble
    color: $_white

.ehon-acti-book-read-unlimited-btn,
.ehon-acti-book-all-page-trial-btn,
.ehon-acti-book-little-page-trial-btn
  border-radius: 20px
  box-shadow: 0 4px 0 0 $_brown_bramble
  border: solid 1px $_brown_bramble
  background-color: $_white
  color: $_brown_bramble
  .mark-color-red,
  .mark-color-yellow,
  .mark-color-blue
    margin-top: 5px
    float: left
.ehon-acti-book-all-page-trial-btn
  &.already-read
    @extend .box-shadow-none
    border-color: rgba(81,51,24,0.4)
.already-read-icon
  @extend .position-absolute
  width: 1.5rem
  height: auto
  top: -9px
  left: -16px
  transform: rotate(-10deg)

.ehon-acti-book-rounded-btn
  border-radius: 20px
  border: solid 1px $_brown_bramble
  background-color: $_white
  color: $_brown_bramble
  font-size: 12px

.ehon-acti-book-all-page-trial-request-link
  width: 100%
  border-radius: 20px
  border: 1px solid $_gray_nobel
  background: $_gray_nobel
  color: $_white
  margin-bottom: 10px
  &:hover, &:active
    color: $_white
    background: $_gray_nobel
h3.ehon-acti-book-content-title
  @extend .h5
  font-weight: bold
.ehon-acti-book-description
  img
    width: 100%
.ehon-acti-book-page-subcategory-items
  ul
    overflow-x: unset
    white-space: unset
    li
      @extend .d-inline-block
      a
        @extend .btn, .btn-outline-primary, .rounded-pill
        margin: 0 5px 10px 0
        padding: 8px 20px
        font-size: $font-size-sm
.ehon-acti-book-click-effect
  animation: click-effect 0.3s linear 0s
  background-color: $_yellow_sweetcorn
  border-radius: 50%
  border: 4px solid $_yellow_sweetcorn
  position: absolute
  z-index: 1000

.ehon-acti-books-walkthrough-item
  font-size: 50px
  font-weight: bold
  text-align: left
  padding: 20px
  color: $_red_scarlet
  display: block
  width: 100vw
  height: 80vh

img.ehon-acti-book-walkthrough-logo
  display: block
  height: 100px
  margin-bottom: 15px

.ehon-acti-book-thumbnail-wrapper
  background-repeat: repeat-y
  background-size: 100% auto
  padding: 20px 15px
.ehon-acti-book-thumbnail
  position: relative
  text-align: center
  background-image: url('../images/shadow.png')
  background-size: 200px
  background-position: center bottom -10px
  background-repeat: no-repeat
  padding-bottom: 40px
  width: 60vw
  margin: 0 auto
.ehon-acti-book-thumbnail__image
  width: 60vw
.ehon-acti-book-thumbnail__like-icon
  position: absolute
  width: 10vw
  bottom: 0
  right: 0
  padding-bottom: 40px

.ehon-acti-book-pickup-area
  @extend .row
  +page-content-title-area($font-size: 15px)
.ehon-acti-book-pickup-area-title
  margin-top: 9px
.ehon-acti-books__remarks
  font-size: 12px
.ehon-acti-books-mark-colors
  background-color: $_white
  border-radius: 100px
  border: $_light_grey solid 1px
  width: auto
  height: 22px
  display: flex
.ehon-acti-books-mark-colors__text
  font-size: 12px
  margin: 0 16px 0 auto

.ehon-acti-book-trial-button-icon
  font-size: 17px
  margin-right: 5px

.ehon-acti-book-item-info
  margin-bottom: 1.0vw
  padding-top: 0.5vw
  padding-left: 6vw
.ehon-acti-book-item-info-title
  font-weight: bold
.ehon-acti-book-item-info-author
  font-size: 12px
  color: $_brown_pale

.ehon-acti-book-alert-message
  display: none
  &.is-captured
    +ehon-acti-book-overlay($color: $_white)
.ehon-acti-book-alert-message-user-locked,
.ehon-acti-book-alert-message-no-screen-capture
  background-color: $_black
  color: $_white
  align-items: center
  width: 70%
  min-height: 100px
  margin: 6rem auto
  border-radius: 22px
.ehon-acti-book-alert-message-user-locked-title,
.ehon-acti-book-alert-message-no-screen-capture-title
  display: block
  text-align: center
  font-weight: bold
  padding: 15px
  padding-top: 20px
.ehon-acti-book-alert-message-user-locked-body,
.ehon-acti-book-alert-message-no-screen-capture-body
  display: block
  text-align: center
  padding: 10px
.ehon-acti-book-alert-message-no-screen-capture-btn
  display: block
  width: 100%
  border-top: 1px solid $_white
  text-align: center
  padding: 10px
  font-size: medium

.ehon-acti-book-android-review-suggestion
  display: none
  &.is-shown
    display: block
.ehon-acti-book-android-review-suggestion-overlay
  +ehon-acti-book-overlay($color: $_black)
.ehon-acti-book-android-review-suggestion-container
  z-index: 99999
  display: block
  background: #ffffff
  width: 80%
  height: 60%
  position: fixed
  top: 0
  right: 0
  left: 0
  margin: 20vw auto
  text-align: center
  +media-breakpoint-up(sm)
    margin: 1vw auto
    height: auto
.ehon-acti-book-android-review-suggestion-flags
  img
    width: 100%
.ehon-acti-book-android-review-suggestion-title
  font-size: 20px
  font-weight: bold
  color: $_red_orange
.ehon-acti-book-android-review-suggestion-body
  font-size: 12px
  font-weight: bold
  +media-breakpoint-up(sm)
    display: none
.ehon-acti-book-android-review-suggestion-buttons
  padding: 16px
  a
    @extend .btn, .btn-outline-primary
    width: 100%
    margin-bottom: 16px
    font-weight: bold
    font-size: 12px
    padding: 8px 0
    &.is-link-to-google-app-store
      background-color: $_red_orange !important
      color: $_white !important
      border-color: $_red_orange !important

// メニュー
.ehon-acti-books-menu
  a
    @extend .d-block, .border-bottom-light-color
    padding: 15px
    padding-right: 30px
    color: $_brown_bramble
.ehon-acti-books-menu__icon
  position: absolute
  right: 10px
  & a:hover, & a:focus
    text-decoration: none
@keyframes click-effect
  0%
    height: 0
    opacity: 1
    transform: translate(0, 0)
    width: 0

  100%
    height: 100px
    opacity: 0
    transform: translate(-50px, -50px)
    width: 100px

@keyframes click-effect
  0%
    height: 0
    opacity: 1
    transform: translate(0, 0)
    width: 0

  100%
    height: 100px
    opacity: 0
    transform: translate(-50px, -50px)
    width: 100px

.ehon-acti-book-promotion-notes
  text-align: center
  font-weight: bold
  margin-bottom: 1rem

.ehon-acti-book-remaining-notes
  text-align: center
  margin-bottom: 1rem
  color: $_brown_bramble
  .count
    margin-left: .25rem
    margin-right: .25rem
    font-size: 1.25rem
    color: $_red_orange
    font-weight: bold
  a
    color: inherit

.ehon-acti-book-spinner-container
  display: none
  &.is-shown
    +ehon-acti-book-overlay($color: $_white)
.ehon-acti-book-spinner
  @extend .spinner-border // bootstrap
  position: fixed
  right: 45vw
  top: 85vw

// タブ
.ehon-acti-book-nav-tabs
  display: flex
  flex-wrap: nowrap
  justify-content: center
  white-space: nowrap
  background-color: white

  +media-breakpoint-down(xs)
    overflow: auto
    a
      padding: 0.5rem

  a
    color: $_gray_nobel
    &:hover
      color: $_gray_nobel

.ehon-acti-book-theme-banner
  img
    width: 100%
    height: auto

.ehon-acti-book-theme-link-banner
  img
    max-width: 375px
    width: 100%
    height: auto

.ehon-acti-books-all-page-trial-note
  // NOTE: .ehon-acti-books-items 配下でも表示が崩れないように、通常ならデフォルトで適用される値を明示している
  text-align: initial
  ul
    margin-top: .5rem
    padding-left: 1rem
    li
      list-style: disc
      display: list-item
      margin: 0

.ehon-acti-book-already-read-type-filter
  display: flex
  justify-content: center
  a
    padding: 8px 12px
    color: $_brown_bramble
    &:hover
      color: $_brown_bramble
    &:focus, &:hover
      text-decoration: none

.ehon-after-viewer-container
  display: none
  padding: 50px 8px 8px 16px
  height: 100%
  overflow-y: auto
  .ehon-acti-book-content-title
    text-align: center
  .ehon-after-viewer-no-ml
    margin-left: -16px

.ehon-acti-book-after-read-shopping-thumbnail
  max-width: 130px
  width: 100%
  height: auto
  object-fit: contain
  object-position: top
  +media-breakpoint-up(md)
    max-width: 250px
