$premium-home-lp-margin-bottom: 30px
.premium-home-lp-banner
  padding: 0 15px
  text-align: center
  img
    display: block
    max-width: 100%
    margin: 30px auto $premium-home-lp-margin-bottom
h1.premium-home-lp-title
  color: $_brown_bramble
  font-weight: bold
  font-size: $font-size-lg
  line-height: 1.43
  border-top: 2px dotted $_brown_bramble
  border-bottom: 2px dotted $_brown_bramble
  padding: 10px 0
  margin: 0 15px $premium-home-lp-margin-bottom
  text-align: center
.premium-home-lp-part
  padding: 0 15px
  margin-bottom: 40px
  img
    display: block
    max-width: 100%
    margin: 0 auto $premium-home-lp-margin-bottom
.premium-home-lp-part-trial
  background-image: url('../images/texture-wood.png')
  padding: 15px
  margin-bottom: $premium-home-lp-margin-bottom
  h2
    font-size: $font-size-lg
    text-align: center
    margin-bottom: 18px
.premium-home-lp-part-trial-item
  overflow: hidden
  margin-bottom: 20px
.premium-home-lp-part-trial-item-link
  &:hover, &:focus
    text-decoration: none
.premium-home-lp-part-trial-item__image
  position: relative
  background: white
  display: block
  float: left
  clear: left
  width: 120px
  height: 120px
  margin-top: 4px
  img
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    max-height: 110px
    display: block
    margin: 0 auto
    width: 100%
.premium-home-lp-part-trial-item__content
  display: block
  margin-top: 0
  margin-left: 142px
  p
    font-weight: bold
    color: $_brown_bramble
    margin: 0 0 10px
  dl, dt, dd
    font-weight: normal
.premium-home-lp-part-trial-item-link
  +page-brown-transparent-link
  font-weight: bold
  margin-top: 10px

.premium-home-lp-footer
  font-size: 0.75rem
