.premium-home-gadget-wrapper
  background-image: url('../images/texture-wood.png')
.premium-home-gadget
  position: relative
  width: 320px
  height: 240px
  margin: 0px
  padding: 5px 15px 15px
  overflow: hidden
.premium-home-gadget-header
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 8px
.premium-home-gadget-header__logo
  margin: 0
  padding: 0
  width: 35%
  img
    width: 100%
    display: inline
.premium-home-gadget-header__eyecatch
  width: 60%
  img
    width: 100%
.premium-home-gadget-content__navigation
  margin-bottom: 12px
  display: flex
  justify-content: space-between
  li
    width: 30%
    display: inline-block
  img
    width: 100%
    border-radius: 1.5px
    box-shadow: 0 0.5px 6px 0 rgba(81, 51, 24, 0.28)
.premium-home-gadget-content__banner
  margin-bottom: 10px
  img
    width: 100%
.premium-home-gadget-label-new
  height: 18px
  display: block
  margin-right: 10px
.premium-home-gadget-content__news
  font-size: $font-size-sm
.premium-home-gadget-content__news-link
  color: $_brown_bramble
  display: flex
  &:hover, &:focus
    text-decoration: none
    color: $_brown_bramble
  p
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-line-clamp: 1
    -webkit-box-orient: vertical
    overflow: hidden
  span
    margin-right: 5px
