.profiles-container
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  display: flex
  justify-content: center
  align-items: center

.profiles-title
  margin: 1.5rem auto
  font-size: 2rem
  font-weight: bold
  +media-breakpoint-down(md)
    font-size: 1rem

.profiles
  display: flex
  flex-direction: row
  align-items: flex-start
  +media-breakpoint-down(xs)
    flex-direction: column
    align-items: center

.profile
  position: relative

.profile-exchange-icon
  margin: 120px 1rem 0
  font-size: 2rem
  +media-breakpoint-down(md)
    margin: 60px 1rem 0
    font-size: 1rem
  +media-breakpoint-down(xs)
    margin: 0
    transform: rotate(90deg)

.profile-exchange-icon + .profile
  +media-breakpoint-down(xs)
    padding-top: 1rem

.profile-link
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
  color: $_black
  &:hover, &:active
    color: $_black

.profile-notice
  position: absolute
  left: 50%
  transform: translateX(-50%)
  margin-top: .75rem
  font-size: .75rem
  color: $_gray_nobel !important
  word-break: keep-all
  +media-breakpoint-down(xs)
    position: initial
    text-align: center
    transform: initial

.parental-gate-information
  font-weight: bold
  text-align: center

.parental-gate-expression
  display: flex
  justify-content: center
  align-items: center

.parental-gate-op
  font-size: 2rem
  margin: 0 1rem

.parental-gate-arg
  font-size: 3rem
  font-weight: bold

.parental-gate-input
  height: 3rem
  width: 5rem
  font-size: 2rem
  padding: 0 10px

.parental-gate-result
  display: none
  font-size: 3rem
  margin-left: 1.5rem
  +media-breakpoint-down(sm)
    right: 0
    font-size: 2rem
    margin-left: 1rem

.parental-gate-button
  padding-left: 4rem
  padding-right: 4rem
  border-radius: 20px
