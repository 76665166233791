.app-guide-hero
  position: relative
  $_lighten_orange: #de583e
  $_darken_orange: #dc422f
  background: repeating-linear-gradient(-45deg, $_lighten_orange 0, $_lighten_orange 2.5px, $_darken_orange 2.5px, $_darken_orange 7.5px)
  padding: 2rem 1rem 2rem
  &:after
    content: ''
    display: block
    height: 35px
    position: absolute
    /* 素材のゴミが見えないように bottom を調整している
    bottom: -2px
    left: 0
    right: 0
    background-image: url('../images/app_guide/wave_white.png')
    background-repeat: repeat-x
    background-position: bottom

.app-guide-ribbon
  position: absolute
  top: 0
  left: 1rem
  display: flex
  flex-direction: column
  align-items: center
  background-color: #e6cb66
  padding: 1rem .5rem .25rem
  font-size: .75rem
  line-height: 1
  text-align: center
  width: 65px
  &:after
    content: ''
    position: absolute
    bottom: -10px
    display: block
    height: 10px
    width: 100%
    border-top: 0
    border-right: 32.5px solid #e6cb66
    border-left: 32.5px solid #e6cb66
    border-bottom: 10px solid transparent

  .app-guide-ribbon__number
    margin-top: .25rem
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    border: 1px solid $_brown_bramble
    border-radius: 100%
    font-size: 1.25rem
    width: 2rem
    height: 2rem

.app-guide-bright
  margin: 0 auto
  display: block
  width: 150px

.app-guide-title
  color: white
  text-align: center
  font-size: 3rem
  font-weight: bold
  line-height: 1
  &.is-small
    font-size: 1.25rem
    line-height: 1.2
  &.is-step5
    font-size: 2.5rem
  .is-step5-first-row
    letter-spacing: -5px

.app-guide-eyecatch
  display: block
  margin: 0 auto
  width: 100%
  height: auto
  &.is-small
    max-width: 200px
  &.is-xsmall
    max-width: 150px

.app-guide-swiper
  max-width: 30rem

.app-guide-step-balloon
  position: relative
  background-image: url('../images/app_guide/balloon_white.png')
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 90px
  height: 90px
  white-space: nowrap
  line-height: 1
  font-size: .75rem
  text-align: center
  margin-bottom: 25px
  color: $_brown_bramble
  text-decoration: none
  &:hover, &:active
    color: $_brown_bramble
    text-decoration: none
  > *
    /* 吹き出しの素材の中でちょうどセンタリングされるように見えるよう位置を調整している
    transform: translateY(-5px)
  &.is-active
    background-image: url('../images/app_guide/balloon_yellow.png')
  &:after
    position: absolute
    bottom: -25px
    content: ''
    height: 40px
    width: 100%
    display: block
    background-image: url('../images/app_guide/balloon_shadow.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: center
    z-index: -1
    opacity: .75

.app-guide-step-balloon__number
  display: flex
  justify-content: center
  align-items: center
  border: 1px solid $_brown_bramble
  border-radius: 100%
  width: 1rem
  height: 1rem
  margin-bottom: .25rem

.app-guide-section
  margin: 2rem auto 0
  padding: 0 1rem
  max-width: 30rem
  &.is-step2
    display: flex
    justify-content: center
  &.is-step4-unlimited
    background-color: #e8e0d9
    padding: 1rem

.app-guide-section-eyecatch
  display: block
  width: 4rem
  height: auto
  margin: 0 auto

.app-guide-section-title
  text-align: center
  font-size: 1.25rem
  font-weight: bold
  letter-spacing: -1.25px
  strong
    font-size: 2.5rem
    color: $_red_orange
    letter-spacing: -5px

.app-guide-section-description
  color: $gray-800
  strong
    color: $_red_orange

.app-guide-go-to-next
  margin-top: 2rem
  text-align: center
  font-size: 1.25rem
  a
    color: $_brown_bramble

.app-guide-footer
  background-color: #e6cb66
  margin-top: 1rem
  padding: .5rem 0
  display: flex
  justify-content: center
  align-items: center

.app-guide-footer-item
  line-height: 1
  margin-left: 1rem
  &:first-child
    margin-left: 0
  &.is-text
    font-size: .75rem
  a
    display: flex
    justify-content: center
    align-items: center
    font-size: 1.25rem
    color: $_brown_bramble
    border-radius: 100%
    border: 1px solid $_brown_bramble
    width: 2rem
    height: 2rem
  &.is-active
    a
      background-color: $_brown_bramble
      color: #e6cb66

.app-guide-step2-screen-wrapper
  position: relative
  margin: 75px 0 50px

.app-guide-step2-more-info
  position: absolute
  left: -80px
  top: -90px
  display: flex
  align-items: center
  line-height: 1
  font-size: 1rem
  > *
    margin-left: 8px
    &:first-child
      margin-left: 0

.app-guide-step2-more-info__image
  height: auto
  &.is-hand
    width: 25px
  &.is-arrow
    width: 100px
    transform: translateY(50%)

.app-guide-step2-guide
  position: absolute
  img
    height: auto
  &.is-guide1
    right: -95px
    top: -40px
    img
      width: 180px
  &.is-guide2
    right: -100px
    top: 60px
    img
      width: 125px
  &.is-guide3
    left: -100px
    top: 50px
    img
      width: 125px
  &.is-guide4
    left: -95px
    bottom: 15px
    img
      width: 150px
  &.is-guide5
    left: -37.5px
    bottom: -55px
    img
      width: 175px
  &.is-guide6
    right: -95px
    bottom: 20px
    img
      width: 125px

.app-guide-description-image
  width: 100%
  height: auto

.app-guide-supervisor
  margin: 0 .25rem
  padding: .5rem
  border: 1px solid $_brown_bramble
  border-radius: 12px

.app-guide-supervisor__about
  display: flex
  align-items: start
  padding: .75rem
  background-color: #fff0eb
  border-radius: 12px

.app-guide-supervisor__photo
  width: 110px
  height: auto
  object-fit: contain
  margin-top: 6px

.app-guide-supervisor__profile
  margin-left: .75rem

.app-guide-supervisor__name
  color: lighten($_brown_bramble, 20%)
  font-size: 1.25rem
  font-weight: bold
  .small
    margin-top: -.25rem
    font-size: .75rem

.app-guide-supervisor__introduction
  margin-top: .5rem
  color: $gray-700
  line-height: 1.2
  font-size: .85rem

.app-guide-supervisor-comment
  position: relative
  margin: .5rem 1rem 0
  padding: 1rem
  background-color: #ffd3c6
  color: $gray-700
  line-height: 1.2
  font-size: .85rem
  border-radius: 12px
  &:before
    position: absolute
    content: ''
    display: block
    height: 1.75rem
    width: .5rem
    left: 2rem
    top: -1.75rem
    border-bottom: 1.75rem solid #ffd3c6
    border-right: .5rem solid transparent
    border-left: .5rem solid transparent

.app-guide-recommend-links-title
  width: 150px
  margin: 0 auto
  display: block

.app-guide-recommend-links
  width: 100%
  table-layout: fixed
  td
    padding: .25rem

.app-guide-recommend-link
  $_bg_color: #ffd3c6
  $_darken_bg_color: darken(#ffd3c6, 2.5%)
  color: $gray-900
  background: repeating-linear-gradient(-67.5deg, $_darken_bg_color 0, $_darken_bg_color 2.5px, $_bg_color 2.5px, $_bg_color 7.5px)
  font-size: 1.25rem
  font-weight: bold
  letter-spacing: 1.25px
  border: $_darken_bg_color solid 3px
  border-bottom: darken(#ffd3c6, 7.5%) solid 5px
  &:active
    margin-top: 3px
    border-bottom-width: 2px
  .small
    font-size: .8rem

.app-guide-age-recommend-head
  position: relative
  background-color: darken(#ffd3c6, 2.5%)
  font-size: 1.25rem
  font-weight: bold
  letter-spacing: 1.25px
  text-align: center
  padding: .5rem

  &:after
    content: ''
    display: block
    position: absolute
    top: .25rem
    left: .25rem
    right: .25rem
    bottom: .25rem
    border: 1px solid darken(#ffd3c6, 15%)

.app-guide-age-recommend-title
  color: lighten($_brown_bramble, 10%)
  margin-top: 1rem
  text-align: center
  font-size: 1.1rem
  font-weight: bold
  white-space: pre-wrap

.app-guide-age-recommend-search-link
  color: white
  font-size: 1rem
  font-weight: bold
  display: flex
  justify-content: space-between
  align-items: center
  padding: 0
  .text
    flex: 1
    padding: .75rem
    text-align: left
    border: darken(#6dd1ab, 20%) solid 3px
    border-bottom-width: 5px
    border-right-width: 0
    border-top-left-radius: 12px
    border-bottom-left-radius: 12px
    background-color: #6dd1ab
  .allow
    padding: .75rem .5rem
    border: 3px darken(#6dd1ab, 20%) solid
    border-bottom-width: 5px
    border-top-right-radius: 12px
    border-bottom-right-radius: 12px
    background-color: darken(#6dd1ab, 10%)

.app-guide-step6-message
  color: lighten($_brown_bramble, 10%)
  font-size: 1rem
