.sugotoku-sessions__container
  @extend .container, .text-center, .mt-3

.sugotoku-sessions__terms-of-use-signin
  input
    -webkit-appearance: none
    box-siging: border-box
    width: 100%
    border: none
    background: $_yellow_dandelion
    border-radius: 25px
    line-height: 50px
    font-size: 14px
    font-weight: bold
    color: $_black
    &:disabled
      background: $_gray_gainsboro
