.promotion-message
  .modal-dialog
    max-width: 480px
    margin: auto
    .modal-body
      padding: 0

.promotion-message-image
  width: 100%
  height: auto
  border-top-left-radius: 0.3rem
  border-top-right-radius: 0.3rem
