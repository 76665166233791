main
  position: relative
.ml-base
  margin-left: 15px
.mr-base
  margin-right: 15px
.mt-base
  margin-top: 15px
.mb-base
  margin-bottom: 15px
=page-category-label-base($top: 0, $left: 0)
  position: absolute
  top: $top
  left: $left
  font-size: $font-size-sm
  font-weight: normal
  display: inline-block
  color: $_brown_bramble
  padding: 0 6px
  line-height: 0
  border-top: 12px solid $_yellow_gorse
  border-right: 6px solid transparent
  border-bottom: 12px solid $_yellow_gorse
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.18))
  z-index: 20
.page-category-label
  +page-category-label-base
.page-content-label-like
  +page-content-label-like($bottom: 54px, $left: -4px)
.page-content-label-deadline
  +page-content-label-deadline
.page-content-label-deadline--movie
  +page-content-label-deadline($top: 0, $bottom: initial)
.page-content-label-new
  +page-content-label-new($bottom: 30px)
.page-content-label-new--movie
  +page-content-label-new($bottom: -3px)
.page-content-label-new--news
  +page-content-label-new($bottom: 4px, $left: 45px)
.page-subcategory-items
  +page-subcategory-items

.page-load-status
  padding-bottom: 15px
.is-loading
  background-image: url('../images/loader.gif')
  background-repeat: no-repeat
  background-position: center
  background-size: 24px auto
  height: 24px
  width: 24px
  margin: 0 auto
.mark-color-red
  +mark-color($background: $_red_vermilion)
  &.is-small
    +mark-color($width: 12px, $height: 12px, $background: $_red_vermilion)
.mark-color-yellow
  +mark-color($background: $_yellow_selective)
  &.is-small
    +mark-color($width: 12px, $height: 12px, $background: $_yellow_selective)
.mark-color-blue
  +mark-color($background: $_blue_deep_sky)
  &.is-small
    +mark-color($width: 12px, $height: 12px, $background: $_blue_deep_sky)
.btn-circle
  position: relative
  display: block
  border-radius: 100px
  background-color: white
  width: 35px
  height: 35px
  text-decoration: none
  color: $_brown_bramble
  font-size: 22px
  font-weight: bold
  z-index: 100
  span, svg
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
.btn-circle-shadow
  @extend .btn-circle
  box-shadow: 0 0 7.5px 0 rgba(0, 0, 0, .6)
.float-right
  float: right
// background image
.bg-white-wood-img
  background: url(../../shared/images/bg_white_wood.png) repeat
// padding
.pt-vw-5
  padding-top: 5vw
.pt-vw-6
  padding-top: 6vw
.pt-vw-7
  padding-top: 7vw
.pt-vw-8
  padding-top: 8vw
.pl-vw-3
  padding-left: 3vw
.pl-vw-4
  padding-left: 4vw
.pl-vw-5
  padding-left: 5vw
.pl-vw-6
  padding-left: 6vw
.pl-vw-7
  padding-left: 7vw
.pl-vw-8
  padding-left: 8vw
// margin
.mb-vw-6
  margin-bottom: 6vw
// border
.border-light-color
  border-color: $_pink_soft_peach
.border-top-light-color
  border-top: $_pink_soft_peach solid 1px
.border-bottom-light-color
  border-bottom: $_pink_soft_peach solid 1px
// box-shadow
.box-shadow-none
  box-shadow: none !important
.is-pre-wrap
  white-space: pre-wrap
.list-decimal
  padding-left: 1rem
  li
    padding-left: .5rem
    list-style-type: decimal
.text-brown
  color: $_brown_bramble
.image-auto
  width: 100%
  height: auto
