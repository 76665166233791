.movies-wrapper
  padding: 20px 15px
  background-image: url('../images/texture-wood.png')
.movies-items-menu-wrapper
  margin-bottom: 20px
.movies-switch-view-link
  +page-header-link
  float: right
.movies-item
  position: relative
  margin-bottom: 20px
  &:last-child
    border-bottom: none
.movies-item-thumbnail-wrapper
  float: left
  width: 46%
.movies-item-info
  color: $_brown_bramble
  float: right
  width: 50%
  margin-left: 4%
.movies-item-info__description
  font-size: $font-size-sm
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: 4
  overflow: hidden
  margin-bottom: 10px
.movies-item-info__label-en
  font-size: $font-size-sm
.movies-item-info__link
  border: 1px solid $_brown_bramble
  background: $_brown_bramble
  color: $_white
  margin-top: 5px
  padding: 0.2rem 0.75rem
.movies-item-separator-line
  border-top: 1px solid $_brown_vanilla
  border-bottom: 1px solid $_white
.movies-item-collection-label
  +page-category-label-base($top: 0, $left: -15px)
.movies-item-thumbnail
  position: relative
.movies-item-thumbnail__image
  width: 100%
.movies-item-thumbnail__label-new
  +page-content-label-new($bottom: 6px)
.movies-item-thumbnail__play-time
  position: absolute
  right: 0
  bottom: 0px
  background: $_brown_bramble
  padding: 2px 9px
  color: $_white
  span
    font-size: $font-size-sm
=movies-item-note-text
  color: $_brown_rastynail
  text-decoration: none
  text-shadow: 0 0.5px 0 $_white
.movies-item-thumbnail-file-size
  +movies-item-note-text
  font-size: $font-size-sm
.movies-item-thumbnail-watched
  +page-content-text-label($background: $_blue_polo, $color: $_white)
.movies-item-thumbnail-copyright
  +movies-item-note-text
  font-size: $font-size-xs
  margin-top: 5px
  margin-bottom: 4px
  line-height: 13px
.movies-item-thumbnail__mask
  background: $_brown_bramble
  width: 40%
  height: 100%
  position: absolute
  top: 0
  right: 0
  opacity: 0.76
  color: $_white
  text-align: center
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
.movies-item-thumbnail__mask-icon
  font-size: 15px
.movies-item-info__title
  font-size: $font-size-sm
  font-weight: bold
  margin: -5px 0 5px
.movies-license
  padding: 20px 15px 0
.movies-license-image
  width: 60px
  height: 60px
  float: left
  margin-right: 15px

.movie-wrapper
  padding: 5px 15px 20px
  background-image: url('../images/texture-wood.png')
.movie-title
  +page-content-title-area
.movie-player-app-link-wrapper
  background: $black
.movie-player-app-link
  display: block
  width: 320px
  height: 180px
  margin: 0 auto
  position: relative
  &.is-loading:before
    display: none
  &:before
    display: block
    content: ''
    background-image: url('../images/parts-play.png')
    background-repeat: no-repeat
    position: absolute
    top: 38%
    left: 50%
    width: 60px
    height: 60px
    margin-left: -20px
.movie-player-app-thumbnail
  height: 100%
  display: block
  margin: 0 auto
.movie-player-base
  max-width: 960px
  &.is-hide
    display: none
.movie-player
  overflow: hidden
  max-width: 320px
  margin: 0 auto
.movie-fallback-url-wrapper
  margin-top: 5px
  display: flex
  justify-content: center
.movie-fallback-url
  color: $_brown_pale
  text-decoration: underline
.movie-size-and-warning-wrapper
  margin-top: 5px
  color: $_brown_pale
  display: flex
  justify-content: space-between
.movie-android-app-movie-palyer-warning
  margin-top: 5px
  color: $_brown_pale
.movie-info-wrapper
  padding: 20px 15px
.movie-info-description
  margin-bottom: 15px
.movie-info-book
  display: flex
.movie-info-book-thumbnail-wrapper
  width: 40%
  img
    width: 100%
    padding: 5% 10%
    border: 1px solid $_brown_spring_wood
    border-radius: 5px
.movie-info-book-detail-wrapper
  margin-left: 6%
  width: 54%
  h3
    font-size: $font-size-lg
.movie-info-book-detail-icon
  width: 35px
  margin-right: 2px
.movie-info-shopping-link
  background: $_green_yellow
  color: $white
  margin-bottom: 10px
  &:link, &:focus, &:hover, &:visited, &:active
    background: $_green_yellow
    color: $white
.movie-info-original-ehon-link
  background: $white
  color: $_brown_bramble
  border-color: $_brown_bramble
  margin-bottom: 10px
  &:link, &:focus, &:hover, &:visited, &:active
    background: $white
    color: $_brown_bramble
    border-color: $_brown_bramble
.movies-pickup-sample
  display: flex
  justify-content: center
  margin-top: -15px
  margin-left: -10px
  margin-right: -10px
  margin-bottom: 20px
  img
    max-width: 600px
    width: 100%
    height: auto
.movie-buttons
  text-align: center
  display: flex
  margin-top: 8px
  .movie-button
    flex: 1
    width: 100%
    background-color: $_brown_bramble
    color: white
    border: 2px solid $_brown_bramble
    border-radius: 5px
    padding: 4px
    & + .movie-button
      margin-left: 4px
  .movie-button--sample
    background-color: white
    color: $_brown_bramble

.movie-header
  position: relative
  display: flex
  width: 100%
  min-height: 3rem
  background-color: $_brown_bramble
  background-image: repeating-linear-gradient(-45deg, lighten($_brown_bramble, 5%) 0px, lighten($_brown_bramble, 5%) 5px, transparent 0px, transparent 10px), none
  color: white
  padding: .1rem .25rem
  font-size: .9rem
  font-weight: bold
.movie-header__back
  text-align: center
  display: flex
  align-items: center
  a
    color: white
    font-size: 1.25rem
.movie-header__back-text
  font-size: 10px
  margin-top: -10px
.movie-header__title
  text-align: center
  width: 100%
  max-width: calc(100% - 120px)
  position: absolute
  top: 50%
  left: 50%
  transform: translateX(-50%) translateY(-50%)
