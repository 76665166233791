.static-pages-ehon-app-guide
  background-color: $_white_ivory
  text-align: center
  h2
    margin: 0 0 10px
    font-size: 20px
    font-weight: bold
  p.catch
    margin: 0 0 50px
    vertical-align: bottom
    img
      width: 300px
      max-width: 100%
      vertical-align: bottom
  p.num
    margin: 0 0 25px
    font-size: 16px
    font-weight: bold
    b
      font-size: 26px
      font-weight: bold
      color: #ff5013
    b:nth-of-type(2)
      color: #ffba00
    b:nth-of-type(3)
      color: #16c0fc
  p.content
    margin: 0 30px 20px
    text-align: left
  .ehon-app-static-page-can-read
    background-color: $_white_drover
    padding: 22px
    .inner
      padding: 30px
      background: $_white
      border-radius: 22px
      text-align: center
      h2
        margin: 0 0 35px
        font-size: 20px
        font-weight: bold
      h3
        margin: 0 0 10px
        font-size: 20px
        font-weight: bold
        span
          position: relative
          padding: 0 0 0 28px
          &:after
            content: " "
            position: absolute
            left: 0
            top: 50%
            margin-top: -11px
            width: 22px
            height: 22px
            background: #16c0fc
            border-radius: 11px
      h3:nth-of-type(2)
        span:after
          background: #ffba00
      h3:nth-of-type(3)
        span:after
          background: #ff5013
      p
        margin: 0 0 45px
        b
          font-weight: bold
          font-size: 18px
      figure
        img
          width: 140px
        figcaption
          font-size: 14px

  section.ehon-app-static-page-regist
    padding: 40px 22px
    text-align: center
    h2
      margin: 0 0 35px
      font-size: 20px
      font-weight: bold
    .box
      padding: 20px 0
      margin: 0 0 10px
      background: #fff
      border: #bcbcbc 1px solid
      border-radius: 16px
      &.member
        border: #ffba00 1px solid
      &.premium
        border: #ffba00 2px solid
      h3
        margin: 0 0 15px
        font-size: 20px
        font-weight: bold
        text-align: center
        span
          font-size: 16px
          font-weight: normal
      ul.tameshiyomi
        width: 180px
        margin: 0 auto
        text-align: left
        li
          position: relative
          padding: 0 0 0 20px
          font-size: 16px
          font-weight: bold
          &:after
            content: " "
            position: absolute
            left: 0
            top: 50%
            margin-top: -8px
            width: 16px
            height: 16px
            background: #16c0fc
            border-radius: 8px
        li:nth-of-type(2):after
          background: #ffba00
        li:nth-of-type(3):after
          background: #ff5013
      p
        width: 180px
        margin: 25px auto 0
        font-size: 16px
        font-weight: bold
        text-align: left
      ul.add
        width: 180px
        margin: 0 auto
        font-size: 16px
        font-weight: bold
        text-align: left
        li
          margin: 0 0 0 25px
          list-style-type: disc
          font-weight: bold
    ul.attention
      display: inline-block
      margin: 0 0 25px
      text-align: left
      li
        position: relative
        padding: 0 0 0 1.2em
        span
          position: absolute
          left: 0
    p.regist
      width: 260px
      margin: 0 auto
      a
        display: block
        background: #fff
        border: #ff5013 1px solid
        border-radius: 25px
        line-height: 48px
        color: #ff5013
        font-weight: bold
