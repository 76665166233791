.special-collection-wrapper
  padding: 15px
.special-collection-news-wrapper
  padding: 0 10px
.special-collection-banner-image
  width: 100%
  object-fit: contain
  max-width: 375px
  display: block
  margin: 0 auto 15px
.special-collection-pickup
  position: relative
  padding: 0 0 25px
=special-collection-list-align
  ul
    li:nth-child(odd)
      float: left
    li:nth-child(even)
      float: right
.special-collection-pickup-wrapper
  padding: 0 10px 10px
  text-align: center
  overflow: hidden
  +special-collection-list-align
.special-collection-pickup-card
  position: relative
  display: inline-block
  width: 47%
  margin: 10px 0
  background: $_white
  a
    display: block
    padding: 10px
  img
    height: 130px
    max-width: 100%
    object-fit: contain
.special-collection-pickup-card__title
  position: absolute
  top: -5px
  left: -5px
  font-size: $font-size-sm
  background: $_brown_bramble
  color: $_white
  padding: 1px 7px
  border-radius: 4px
  line-height: 1.5
  text-shadow: none
=special-collection-ehon-image
  height: 100px
  object-fit: contain
.special-collection-pickup-card__description
  position: absolute
  bottom: 0
  left: 0
  z-index: 1
  width: 100%
  color: $_brown_bramble
  background: rgba(255,255,255,0.8)
  font-size: $font-size-sm
  padding: 8px
  line-height: 1.3
  p
    display: -webkit-box
    -webkit-box-orient: vertical
    -webkit-line-clamp: 2
    overflow: hidden
    margin: 0
    text-align: left
.special-collection-banner-link
  margin-bottom: 16px
.special-collection-banner-link__image
  width: 100%
.special-collection-back-link
  +page-brown-link
