.mypage-nav
  padding: 1rem 1rem 0

.mypage-kids-history
  display: flex
  border-top: 1px solid #bbb
  padding: 1rem
  color: $_brown_bramble
  +media-breakpoint-down(sm)
    display: block

  &:first-child
    border-top: 0

.mypage-kids-history__playable
  display: flex
  +media-breakpoint-down(sm)
    font-size: .9rem

.mypage-kids-history__image
  width: 120px
  height: 120px
  object-fit: contain
  +media-breakpoint-down(sm)
    width: 100px
    height: 100px

.mypage-kids-history__description
  margin-left: 1rem

.mypage-kids-history__title
  font-weight: bold

.mypage-kids-history__history, .mypage-kids-history__type
  color: #777
  margin-top: .5rem
  +media-breakpoint-down(sm)
    margin-top: .25rem

.mypage-kids-history__history
  display: flex
  align-items: center
  +media-breakpoint-down(sm)
    display: block

.mypage-kids-history__play-type
  display: flex
  align-items: center
  margin-left: 1rem
  +media-breakpoint-down(sm)
    margin-top: .25rem
    margin-left: 0

.mypage-kids-history__personal-rating
  margin-left: auto

.mypage-kids-history-rating
  display: flex
  list-style: none
  padding: 0
  margin: 0
  +media-breakpoint-down(sm)
    display: grid
    justify-content: initial
    column-gap: 0
    grid-template-columns: 25% 25% 25% 25%
    margin-top: .5rem

.mypage-kids-history-rating__item
  display: flex
  flex-direction: column
  color: #ccc
  text-align: center
  margin-left: .5rem
  &:first-child
    margin-left: 0

.mypage-kids-history-rating__item--selected
  color: #333

.mypage-kids-history-rating__item-icon
  font-size: 3rem
  +media-breakpoint-down(sm)
    font-size: 2rem

.mypage-kids-history__signal
  display: inline-block
  width: 20px
  height: 20px
  border-radius: 50%

.mypage-kids-history__signal--unlimited
  background-color: $_red_vermilion
.mypage-kids-history__signal--all-trial
  background-color: #ffba00
