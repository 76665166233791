.ehon-already-all-page-trial-icon-component
  position: absolute
  left: 16%
  bottom: 4%
  img.ehon-already-all-page-trial-icon-component__icon
    width: 6vw
    height: 6vw
    transform: rotate(30deg)
  &.is-top-page
    left: 14%
    img.ehon-already-all-page-trial-icon-component__icon
      max-width: 32px
      max-height: 32px
  &.is-ranking-page
    left: unset
    right: 0
