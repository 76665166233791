.content-modal
  .modal-dialog
    position: relative
    margin: 0
    max-width: 100%
    height: 100vh
  .modal-content
    position: absolute
    top: 0
    bottom: 0
  .modal-body
    padding: 0
    overflow-y: scroll
  .content-modal-spinner
    height: 100%
    display: flex
    justify-content: center
    align-items: center
