=page-content-label-like($bottom: 45px, $left: -3px, $height: 22px, $font-size: $font-size-xxs)
  position: absolute
  bottom: $bottom
  left: $left
  img
    width: $height
    height: $height
=page-content-label-new($top: initial, $bottom: 6px, $right: initial, $left: -2px, $height: 19px)
  position: absolute
  top: $top
  bottom: $bottom
  right: $right
  left: $left
  img
    height: $height
=page-content-label-deadline($top: initial, $bottom: 17px, $right: -5px, $height: 35px, $font-size: $font-size-xs, $line-height: 12px, $pt: 5px)
  position: absolute
  top: $top
  bottom: $bottom
  right: $right
  background: $_white
  border-radius: 3px
  color: $_brown_bramble
  font-size: $font-size
  padding-top: $pt
  height: $height
  width: $height
  text-align: center
  line-height: $line-height
  box-shadow: 0 0.5px 0.5px 0 rgba(81, 51, 24, 0.2)
=page-content-title-text-style
  color: $_brown_bramble
  font-weight: normal
  text-shadow: 0 0.5px 0 $_white
$page-category-icon-height: 40px
=page-category-icon
  width: $page-category-icon-height
  margin-right: 2px
=page-contents-sort-menu
  font-size: $font-size-sm
  dt
    display: inline-block
    font-weight: normal
    color: $_brown_bramble
    margin-right: 15px
  dd
    display: inline-block
    color: $_brown_bramble
  span
    &.is-selected
      font-weight: bold
  a
    color: $_brown_pale
    text-decoration: underline
    &:hover, &:focus
      color: $_brown_pale
=page-header-link
  +page-content-title-text-style
  display: block
  text-decoration: underline
  margin: auto 0
  &:hover
    color: $_brown_bramble
=page-content-title-area($bottom: 15px, $font-size: $font-size-lg)
  color: $_brown_bramble
  text-align: center
  h2
    margin-bottom: $bottom
    font-size: $font-size
    font-weight: bold
  p
    margin-bottom: 5px
=page-content-text-label($background: $_brown_bramble, $color: $_white)
  display: inline-block
  background: $background
  border-radius: 10px
  color: $color
  padding: 0px 8px
  text-shadow: none
  margin-right: 3px
  font-size: $font-size-sm
=page-subcategory-items
  ul
    overflow-x: scroll
    white-space: nowrap
    padding-bottom: 12px
  li
    display: inline-block
    a
      display: block
      padding: 0px 10px
      margin: 0 3px 6px 0
      border-radius: 15px
      background: $_white
      font-size: $font-size-sm
      color: $_brown_bramble
      text-decoration: none
    &.is-active a
      display: block
      padding: 0px 10px
      margin: 0 3px 6px 0
      border-radius: 15px
      background: $_brown_bramble
      font-size: $font-size-sm
      color: $_white
      text-decoration: none
=page-content-title
  +page-content-title-text-style
  font-family: HiraginoSans-W7
  font-size: $font-size-lg
  line-height: 1.43
  border-top: 1px dotted $_brown_bramble
  border-bottom: 1px dotted $_brown_bramble
  padding: 10px 0
=page-brown-link
  border: 1px solid $_brown_bramble
  background: $_brown_bramble
  color: $_white
  width: 100%
  margin-bottom: 10px
  &:hover, &:focus
    background: $_brown_bramble
    border: 1px solid $_brown_bramble
    color: $_white
=page-brown-transparent-link
  border: 1px solid $_brown_bramble
  background: transparent
  color: $_brown_bramble
  width: 100%
  margin-bottom: 10px
  &:hover, &:focus
    background: transparent
    border: 1px solid $_brown_bramble
    color: $_brown_bramble
=mark-color($width: 16px, $height: 16px, $background: $_red_vermilion)
  width: $width
  height: $height
  border-radius: 100px
  background-color: $background
