.ehon-acti-book-page-footer
  height: var(--app-footer-height)
  position: fixed !important
  position: absolute
  bottom: 0
  width: 100%
  background-color: $_white
  z-index: 100
  line-height: 1.5
  .page-content-category-footer-button
    bottom: 75px
.ehon-acti-book-page-footer-menu
  display: flex
  justify-content: space-around
  align-items: center
  height: 100%
  padding: 4px 0
  width: 100%
  max-width: 640px
  margin: 0 auto
body.is-ios
  .ehon-acti-book-page-footer-menu
    margin-top: -5px
.ehon-acti-book-page-footer-menu-item-icon
  font-size: 22px
.ehon-acti-book-page-footer-menu-item-link
  font-size: 11px
  text-align: center
  padding: 2px 8px
  border-radius: 6px
  span
    display: block
    text-decoration: none
    color: $_brown_pale
    white-space: nowrap
    &:focus
      text-decoration: none !important
  img
    height: 23px
    margin: 5px
    object-fit: contain
  &.is-active
    background-color: lighten($_brown_bramble, 80%)

.page-content-category-footer-button
  display: none
  background: $_brown_bramble
  border-radius: 50%
  color: $_white
  font-size: 22px
  padding: 4px
  height: 40px
  width: 40px
  text-align: center
  position: fixed
  bottom: 20px
  right: 10px
  z-index: 999
  cursor: pointer

.sugotoku-footer-menu
  .page-content-category-footer-button
    line-height: 1.5
    &.is-scroll
      bottom: 110px
    &.is-menu
      bottom: 60px
      display: block

  .modal-dialog
    position: fixed
    left: 0
    right: 0
    bottom: 50px
