// NOTE: safari に効く style, 変えるときは safari で確認する
.owl-carousel .owl-item
  img
    width: initial !important

$font-size-lg: 1.1rem !default
$font-size-base: 0.88rem !default
$font-size-xs: 0.625rem !default
$font-size-xxs: 0.562rem !default

.owl-theme .owl-dots .owl-dot span
  width: 10px
  height: 10px
  margin: 5px 7px
  background: #D6D6D6
  display: block
  -webkit-backface-visibility: visible
  transition: opacity .2s ease
  border-radius: 30px
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span
  background: $_brown_bramble

// Bootstrap
$font-family-base: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif !default

//------------------------------------
// COLOR primary
//------------------------------------

// NOTE: 色だけ上書きしても反映されなかったので必要なクラスもオーバーライド
$primary_t1: $_brown_bramble
$primary_t2: #291a0c
$primary_t3: #c68040
$primary_t4: #cc8d54
$primary_t5: #000000
$primary_t6: #1d1208
$primary_t7: #35210f
$primary_t8: #000000
$primary_t9: #d19763

$primary_shadow: rgba(81, 51, 24, 0.5)
$primary_text_color_dark_bright: $_white

.btn-outline-primary
  color: $primary_t1 !important
  background-color: $primary_text_color_dark_bright !important
  border-color: $primary_t1 !important

.btn-outline-primary:hover
  color: $primary_text_color_dark_bright !important
  background-color: $primary_t1 !important
  border-color: $primary_t1 !important

.btn-outline-primary:focus, .btn-outline-primary.focus
  box-shadow: none !important
  text-decoration: none !important

.btn-outline-primary.disabled, .btn-outline-primary:disabled
  color: $primary_t1 !important
  background-color: transparent !important

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle
  color: $primary_text_color_dark_bright !important
  background-color: $primary_t1 !important
  border-color: $primary_t1 !important

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus
  box-shadow: none !important

//------------------------------------
// COLOR secondary
//------------------------------------

$secondary_t1: $_red_orange
$secondary_t2: #cc2f00
$secondary_t3: #ffb49e
$secondary_t4: #ffc8b7
$secondary_t5: #841e00
$secondary_t6: #bc2b00
$secondary_t7: #db3200
$secondary_t8: #511200
$secondary_t9: #ffd7cc

$secondary_shadow: rgba(255, 59, 0, 0.5)
$secondary_text_color_dark_bright: #fff

.btn-outline-secondary
  color: $secondary_t1 !important
  background-color: transparent !important
  border-color: $secondary_t1 !important

.btn-outline-secondary:hover
  color: $secondary_text_color_dark_bright !important
  background-color: $secondary_t1 !important
  border-color: $secondary_t1 !important

.btn-outline-secondary:focus, .btn-outline-secondary.focus
  box-shadow: 0 0 0 0.2rem $secondary_shadow !important

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled
  color: $secondary_t1 !important
  background-color: transparent !important

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle
  color: $secondary_text_color_dark_bright !important
  background-color: $secondary_t1 !important
  border-color: $secondary_t1 !important

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus
  box-shadow: 0 0 0 0.2rem $secondary_shadow !important
