=premium-home-ehon-image
  height: 100px
  object-fit: contain

$premium-home-icon-height: 34px

.premium-home-icon
  +page-category-icon

.premium-home-terms-wrapper
  padding: 0 15px
  border-bottom: 1px solid $_brown_spring_wood
  h2
    +page-content-title
    border-top-width: 0
  p.update
    text-align: right

.premium-home-third-party-data-collecting-content-wrapper
  tr.label
    th div
      margin-top: .5rem
    &:first-child
      th div
        margin-top: 0
