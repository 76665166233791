.premium-service-guide
  background: #FFFAED
  font-size: 14px
  padding-bottom: 142px
  &.is-premium
    padding-bottom: 20px

.premium-service-guide-image
  width: 100%
  height: auto
  max-width: 36rem
  margin: 0 auto
  display: block

.premium-service-guide__cancel
  position: relative
  max-width: 36rem
  margin: 0 auto

.premium-service-guide__cancel-link-1
  position: absolute
  top: 47%
  left: 7.5%
  right: 82.5%
  bottom: 45%
  cursor: pointer

.premium-service-guide__cancel-link-2
  position: absolute
  top: 56%
  left: 16.5%
  right: 73.75%
  bottom: 36%
  cursor: pointer

.premium-service-guide-table-section
  background: #fff1ec
  padding: 15px

.premium-service-guide-table-section-title
  text-align: center
  font-weight: bold
  font-size: 1.25rem

.premium-service-guide-table-wrapper
  background: white
  padding: 15px
  width: 100%
  max-width: 36rem
  margin: 0 auto

.premium-service-guide-table
  width: 100%
  ul
    padding-left: 1rem
    li
      list-style: disc

.premium-service-guide-table-header
  font-bold: normal
  &.is-title
    padding: 5px 10px
    color: #FF3408
  &.is-premium
    background: #FF3408
    border-radius: 5px 0 0 0
    font-size: 12px
    color: #fff
    text-align: center
  &.is-member
    background: #A4A4A4
    border-radius: 0 5px 0 0
    font-size: 12px
    color: #fff
    text-align: center

.premium-service-guide-table-summary
  th
    cursor: pointer
    position: relative
    padding: 10px 32px 10px 10px
    &::after
      content: " "
      position: absolute
      right: 10px
      top: 50%
      width: 12px
      height: 7px
      background: url('../images/ehon_acti_books/static_pages/premium_service_guide/ico_table_arrow.svg') center center no-repeat
      transform: translateY(-50%)
      transition-duration: 0.3s
    &.is-open
      &::after
        transform: translateY(-50%) rotate(180deg)

.premium-service-guide-table-summary, .premium-service-guide-table-detail
  &.is-odd
    th, td
      background: #FEF3F1
    td.is-member
      background-color: #F2F1F1

.premium-service-guide-table-summary
  .premium-service-guide-table-cell
    padding: 10px 0
    text-align: center
    font-size: 30px
    line-height: .8
    &.is-premium
      width: 70px
      background: rgba(255,52,8,0.57)
      color: #fff
    &.is-member
      width: 55px
      color: #A4A4A4
    &.is-text
      font-size: 10px
      line-height: 1

.premium-service-guide-table-detail
  td
    display: none
    padding: 10px
    font-size: 12px
    .small
      display: block
      font-size: 10px
      margin-top: 4px

// NOTE: views.ehon_acti_books.static_pages.help_html で使っている
.premium-service-guide__restore
  display: block
  margin: 16px auto 0
  max-width: 300px

.premium-service-guide-footer
  position: fixed !important
  padding: 15px 0 10px 0
  bottom: 0
  width: 100%
  background-color: $_green_fuego
  z-index: 100
  margin-bottom: var(--app-footer-height)

.premium-service-guide-footer__title
  color: $_green_oracle
  font-size: 1rem
  font-weight: bold
  text-align: center

.premium-service-guide-footer__register
  border: none
  display: block
  margin: 0 auto 10px
  max-width: 300px
  text-align: center
  font-weight: bold
  flex-grow: 1
  & + &
    margin-left: 0.8rem

.premium-service-guide-bottom-actions
  position: fixed !important
  padding: 15px 0 10px 0
  bottom: 0
  width: 100%
  background: #FFFAED
  z-index: 100
  margin-bottom: var(--app-footer-height)

.premium-service-guide-bottom-actions__title
  font-weight: bold
  color: $_black
  font-size: 18px
  text-align: center
  .is-small
    font-size: 12px

.premium-service-guide-purchase-table
  display: grid
  column-gap: 12px
  row-gap: 4px
  padding: 0 1rem
  grid-template-columns: repeat(2, minmax(0, 1fr))

.premium-service-guide-purchase__appeal
  color: $_black
  font-size: .75rem

.premium-service-guide-purchase__button
  color: $_white
  text-align: center
  display: block
  padding: .25rem 0
  font-size: 16px
  border-radius: 9999px
  .is-small
    font-size: 12px
  .is-xsmall
    font-size: 8px
  &.is-monthly
    background-color: #FF562A
  &.is-yearly
    background-color: #FF9300

.in-app-purchase-confirm-content
  max-width: 36rem
  margin: 0 auto

.in-app-purchase-confirm-content__head
  text-align: center
  font-size: 1rem

.in-app-purchase-confirm-content__next
  font-size: .9rem

.in-app-purchase-confirm-content__steps
  display: flex
  justify-content: center

.in-app-purchase-confirm-content__step
  position: relative
  border: 3px solid $_red_orange
  padding: 4px
  border-radius: 10px
  margin-left: 16px
  &:before
    position: absolute
    display: block
    content: ""
    left: -12px
    top: 50%
    transform: translateY(-50%)
    border-left: 6px solid $_red_orange
    border-top: 6px solid transparent
    border-bottom: 6px solid transparent
  &:first-child
    margin-left: 0
    &:before
      display: none

.in-app-purchase-confirm-content__step-name
  color: white
  background-color: $_red_orange
  padding: 2px 8px
  border-radius: 5px
  text-align: center

.in-app-purchase-confirm-content__step-title
  margin-top: 4px
  text-align: center
  font-size: .8rem

.in-app-purchase-confirm-content__step-description
  text-align: center
  font-size: .5rem
  margin-top: .25rem
