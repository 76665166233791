.acti-books-contents
  padding: 0
  background-image: url('../images/texture-wood.png')
  background-repeat: repeat-y
  background-size: 100% auto

.acti-books-items
  padding: 8vw 1vw 0 3vw
  text-align: left
  &.space-top
    padding-top: 14vw
  ul
    li
      display: inline-block
      vertical-align: top
      margin-right: 2vw
      &:nth-child(3n)
        margin-right: 0
.acti-books-item
  background: url('../images/shadow.png') center 7vw no-repeat
  background-size: 28vw
  height: 29vw
  width: 29vw
  padding-bottom: 10vw
  margin-bottom: 6vw
  text-align: center
  display: flex
  justify-content: center
  align-items: flex-end
.acti-books-item-thumbnail-wrapper
  display: inline-block
  position: relative
  &.is-wider
    width: 29vw
  &.is-longer
    height: 29vw
.acti-books-item-thumbnail
  &.is-wider
    width: 29vw
  &.is-longer
    height: 29vw
.acti-book-item-thumbnail__label-new
  +page-content-label-new($bottom: 6px)
.acti-book-item-thumbnail__label-like
  +page-content-label-like($bottom: 32px, $left: -4px)
.acti-book-item-thumbnail__label-deadline
  +page-content-label-deadline($top: initial, $bottom: -2px, $right: -2px)
.acti-book-author
  display: block
  dt
    display: inline-block
  dd
    display: inline-block
.acti-book-news-columns-wrapper
  padding: 20px 0
.acti-book-purchase-confirmation
  margin: 0 15px
.acti-book-purchase-confirmation__description
  margin-bottom: 15px
.acti-book-move-link
  display: inline-block
  border: 1px solid $_brown_bramble
  background: $_brown_bramble
  color: $_white
  width: 48%
  float: right
  margin: 0
  &:hover, &:focus
    background: $_brown_bramble
    border: 1px solid $_brown_bramble
    color: $_white
.acti-book-back-link
  display: inline-block
  border: 1px solid $_red_vermilion
  background: $_white
  color: $_red_vermilion
  width: 48%
  margin: 0
  &:hover, &:focus
    color: $_red_vermilion
    background: $_white
    border: 1px solid $_red_vermilion
