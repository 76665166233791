.static-page-signup-section
  max-width: 500px
  padding: 0 1rem

.static-page-signup-benefit
  position: relative
  border: 2px $_red_orange solid
  border-radius: 12px
  padding: 1rem
  &:before
    content: ' '
    position: absolute
    top: -16px
    left: 50%
    transform: translateX(-6px)
    border-bottom: 16px solid $_red_orange
    border-right: 6px solid transparent
    border-left: 6px solid transparent
    border-top: 0

.static-page-signup-benefit__title
  font-weight: bold

.static-page-signup-benefit__detail
  padding-left: 1rem
  li
    list-style: disc

.static-page-signup-more-benefit
  background: $gray-200
  border-radius: 6px
  padding: 1rem
