.announcement-list-item
  display: block
  width: 100%
  max-width: 600px
  margin: 0 auto
  padding: 1rem 1.5rem
  color: $_brown_bramble
  & + &
    border-top: 1px solid #bbb
  &:active, &:focus, &:hover
    color: $_brown_bramble
    text-decoration: none

.announcement-list-item--unread
  font-weight: bold

.announcement-list-item__header
  display: flex
  justify-content: space-between
  align-items: baseline

.announcement-list-item__title
  font-size: 1rem

.announcement__category, .announcement-list-item__new
  font-size: .75rem
  padding: .025rem .5rem
  border-radius: 9999px
  font-weight: bold
  color: white

.announcement-list-item__new
  background-color: $_red_orange
.announcement__category--maintenance
  background: $gray-600
.announcement__category--contents
  background: $_green_york
.announcement__category--release
  background: $_yellow_tacha
.announcement__category--other
  background: $_blue_polo
.announcement__category--important
  background: $_red_crimson
.announcement__category--campaign
  background: $_red_sweet

.announcement-list-item__date
  text-align: right
  font-size: .8rem
  color: $gray-600

.announcement-container
  width: 100%
  max-width: 600px
  margin: 0 auto
  padding: 1rem 1.5rem

.announcement__title
  font-size: 1.25rem

.announcement__eyecatch
  width: 100%
  height: auto

.announcement__link
  display: block
  padding: .25rem 1rem
  text-align: center
  background-color: white
  color: $_brown_bramble
  font-size: 1rem
  border: 1px solid $_brown_bramble
  border-radius: 9999px

.announcements-category-filter
  overflow-x: auto
  white-space: nowrap
  width: 100%
  max-width: 600px
  margin: 0 auto

.announcements-category-filter__item
  a
    display: block
    padding: 0 10px
    border-radius: 9999px
    background-color: white
    color: $_brown_bramble
    font-size: .75rem
    text-decoration: none
  &.is-active
    a
      background-color: $_brown_bramble
      color: white
