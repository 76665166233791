.campaign-2023-spring
  padding: 0.25rem 0.5rem
  background: rgb(255, 255, 180)
  font-size: 0.8rem
  text-align: center

.campaign
  background-color: $_white_ivory

.campaign-container
  padding: 1rem 1rem 140px 1rem
  max-width: 600px
  margin: 0 auto

.campaign-banner-wrapper
  margin-left: -1rem
  margin-right: -1rem

.campaign-highlight
  position: relative
  background-color: white
  padding: .5rem 1rem
  border-radius: 6px
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)

.campaign-voice
  &:before
    content: ''
    position: absolute
    top: -14px
    left: 18.5px
    border-style: solid
    border-width: 7px
    border-color: transparent transparent #f5f5f5 transparent
  &:after
    content: ''
    position: absolute
    top: -12px
    left: 19px
    border-style: solid
    border-width: 6px
    border-color: transparent transparent white transparent

.campaign-bottom-sheet
  position: fixed
  bottom: var(--app-footer-height)
  width: 100%
  background-color: $_green_fuego

.shigotoba-movie-thumbnail-wrapper
  position: relative
  img
    border-radius: 6px
  .play-icon
    color: white
    position: absolute
    font-size: 3rem
    opacity: .8
    top: 50%
    left: 50%
    transform: translateX(-50%) translateY(-50%)
