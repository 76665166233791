// refs: https://www.htmlcsscolor.com
$_brown_bramble: darken(#513318, 7.5%)
$_brown_spring_wood: #ebe4dd
$_brown_pale: #967253
$_brown_rastynail: #8b572a
$_brown_vanilla: #cbb39d
$_white: #ffffff
$_white_chiffon: #fcf9ca
$_white_ivory: #fcfaef
$_white_drover: #fff29e
$_black: #000000
$_gray_gainsboro: #e2e2e2
$_gray_smoke: #f0f0f0
$_gray_nobel: #989898
$_yellow_gorse: #fff03a
$_yellow_sweetcorn: #ffe368
$_yellow_tacha: #cbb867
$_yellow_dandelion: #ffe162
$_yellow_selective: #efba00
$_red_crimson: #e30025
$_red_venetian: #d0021b
$_red_vermilion: #fd4c10
$_red_orange: #ff3b00
$_red_maroon: #660000
$_red_sweet: #ef9696
$_red_scarlet: #f01e09
$_red_vermilion: #fd4e11
$_green_york: #81bf77
$_green_norway: #9cb482
$_green_yellow: #93cf1b
$_green_fuego: #bcd735
$_green_oracle: #335555
$_blue_polo: #83a1c8
$_blue_deep_sky: #16c0fc
$_light_grey: #d6d6d6
$_pink_soft_peach: #f1e0e0

//bootstrap overrides
$body-color: $_brown_bramble
