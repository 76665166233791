.app-home-category-content-item
  position: relative
  display: inline-block

.app-home-ohanashi-book-item
  margin-top: 12px
  a
    display: flex
    align-items: flex-start
    color: $_brown_bramble
  &:first-child
    margin-top: 0

.app-home-ohanashi-book-item__book-thumbnail
  width: 100px
  height: 150px
  object-fit: contain
  object-position: top

.app-home-ohanashi-book-item__daily-story
  flex: 1
  margin-left: 10px

.app-home-ohanashi-book-item__story
  margin-top: 6px
  display: flex

.app-home-ohanashi-book-item__story-thumbnail
  width: 70px
  height: 90px
  object-fit: contain

.app-home-ohanashi-book-item__date
  font-size: .8rem

.app-home-ohanashi-book-item__story-notes
  flex: 1
  margin-left: 6px

.app-home-ohanashi-book-item__story-title
  font-weight: bold

.app-home-ohanashi-book-item__story-description
  font-size: .75rem

.app-home-special-collection-banner
  & + &
    margin-top: 4px
  img
    width: 100%
    object-fit: contain
    max-width: 375px
    display: block
    margin: 0 auto

.app-home-sugotoku-footer
  padding: 1rem 0
  text-align: center
  font-size: .75rem
  li
    margin-top: 4px
